const styles = {
  rootSection: {
    textAlign: 'center',
    marginBottom: '30px',
    '& h5': {
      color: '#ff6600',
    },
  },
  btnItem: {
    display: 'flex',
    padding: '20px',
  },
  rootItemImage: {
    height: '100px',
    width: '100px',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  rootItemInfo: {
    marginLeft: '20px',
    textAlign: 'initial',
    width: '100%',
    '& p': {
      fontWeight: 'lighter',
      textTransform: 'initial',
      textAlign: 'initial',
    },
  },
  '@media screen and (max-width: 580px)': {
    btnItem: {
      '& span': {
        flexWrap: 'wrap',
      },
    },
    rootItemInfo: {
      marginTop: '20px',
    },
  },
}

export default styles
