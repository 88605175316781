import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { List, ListItem, makeStyles } from '@material-ui/core'
import { Language } from '@material-ui/icons'
import CustomDropdown from '../CustomDropdown/CustomDropdown'
import Button from '../CustomButtons/Button'
// import { logout } from '../../redux/actions/userActions'
import styles from './styles/websiteNavbarStyle'

const useStyles = makeStyles(styles)

const WebsiteNavbar = () => {
  // const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()

  const { userInfo } = useSelector((state) => state.userLogin)

  // const logoutHandler = () => {
  //     dispatch(logout())
  // }
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button component={Link} to='/writing-winners' color='transparent' className={classes.navLink}>
          Wiriting Winners
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button component={Link} to='/how-it-works' color='transparent' className={classes.navLink}>
          Map
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button component={Link} to='/how-it-works' color='transparent' className={classes.navLink}>
          Contact
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText='Faqs'
          buttonProps={{
            className: classes.navLink,
            color: 'transparent',
          }}
          dropdownList={[
            <div onClick={() => history.push('/faqs?teacher=1')} className={classes.dropdownLink}>
              Teacher
            </div>,
            <div onClick={() => history.push('/faqs?student=1')} className={classes.dropdownLink}>
              Student
            </div>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText='Get Started'
          buttonProps={{
            className: classes.navLink,
            color: 'transparent',
          }}
          dropdownList={[
            <div onClick={() => history.push('/auth/login')} className={classes.dropdownLink}>
              {userInfo
                ? userInfo.isAdmin || userInfo.isSuper
                  ? 'Go to Control Panel'
                  : userInfo.role === 'teacher' && !(userInfo.isAdmin || userInfo.isSuper)
                  ? 'Go to Teacher Area'
                  : userInfo.role === 'student' && !(userInfo.isAdmin || userInfo.isSuper) && 'Go to Student Area'
                : 'Login'}
            </div>,
          ]}
        />
      </ListItem>

      {/* <ListItem className={classes.listItem}>
                {userInfo ? (
                    <CustomDropdown
                        noLiPadding
                        buttonText={userInfo.name}
                        buttonProps={{
                            className: classes.navLink,
                            color: 'transparent',
                        }}
                        buttonIcon={Apps}
                        dropdownList={[
                            <Link to={userInfo.role === 'student' ? '/student/profile' : '/teacher/profile'} className={classes.dropdownLink}>
                                My Profile
                            </Link>,
                            <div onClick={logoutHandler} className={classes.dropdownLink}>
                                Logout
                            </div>,
                        ]}
                    />
                ) : (
                    <Button component={Link} to='/login' color='transparent' className={classes.navLink}>
                        Sing In
                    </Button>
                )}
            </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={`hola`}
          buttonProps={{
            className: classes.navLink,
            color: 'transparent',
          }}
          buttonIcon={Language}
          dropdownList={[
            <div onClick={() => changeLanguage('en')} className={classes.dropdownLink}>
              English
            </div>,
            <div onClick={() => changeLanguage('es')} className={classes.dropdownLink}>
              Español
            </div>,
            <div onClick={() => changeLanguage('fr')} className={classes.dropdownLink}>
              Français
            </div>,
            <div onClick={() => changeLanguage('it')} className={classes.dropdownLink}>
              Italiano
            </div>,
          ]}
        />
      </ListItem> */}
    </List>
  )
}

export default WebsiteNavbar
