import { Button, Card, Grid, makeStyles } from '@material-ui/core'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCategoriesByType } from 'redux/actions/categoryActions'
import styles from './styles/podcastsScreenStyle'

const useStyles = makeStyles(styles)

const PodcastsScreen = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { categories } = useSelector((state) => state.categoryByType)

  useEffect(() => {
    dispatch(getCategoriesByType('podcast'))
  }, [dispatch])

  return (
    <div>
      <Card className={classes.cardRoot}>
        <Grid container className={classes.gridCard}>
          <Grid item md={12}>
            <div className={classes.titlesSection}>
              <h1>Section Podcasts</h1>
              <h3>Welcome to The Magazine Podcasts</h3>
              <p>Check out our podcasts and conversations here!</p>
              <h4>Podcasts</h4>
            </div>
            <Grid container spacing={3} className={classes.sectionCategories}>
              {categories &&
                categories.map((category) => (
                  <Grid key={category._id} item md={6} sm={6} xs={12}>
                    <Button>
                      <img src={`/assets/img/podcasts/1background${category.name.replace(/ /g, '')}.png`} />
                    </Button>
                  </Grid>
                ))}
            </Grid>
            <h4>Conversations</h4>
            <Grid container spacing={2}>
              <Grid item md={4} sm={12} xs={12}>
                <Button className={classes.buttonConversation}>
                  <div className={classes.activitieImage}>
                    <span>Daily Activities</span>
                  </div>
                </Button>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Button className={classes.buttonConversation}>
                  <div className={classes.schoolImage}>
                    <span>School</span>
                  </div>
                </Button>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Button className={classes.buttonConversation}>
                  <div className={classes.streetImage}>
                    <span>Street</span>
                  </div>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

export default PodcastsScreen
