import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Card, CardActionArea, CardContent, CardMedia, Grid, makeStyles } from '@material-ui/core'
import { useQueryParams } from 'shared/customHooks/useQueryParams'
import { getEarlyReading } from 'redux/actions/earlyReadingActions'
import EarlyReadingByCategory from './components/EarlyReadingByCategory'
import styles from './styles/earlyReadingScreenStyle'

const useStyles = makeStyles(styles)

const EarlyReadingScreen = () => {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const queries = useQueryParams()

  const [earlyReadingVowels, setEarlyReadingVowels] = useState([])
  const [earlyReadingPhonics, setEarlyReadingPhonics] = useState([])
  const [earlyReading, setEarlyReading] = useState(null)

  const { earlyreadings } = useSelector((state) => state.earlyReadingList)

  useEffect(() => {
    dispatch(getEarlyReading())
  }, [dispatch])

  useEffect(() => {
    if (earlyreadings) {
      const earlyReading = earlyreadings.find((earlyreading) => earlyreading._id === queries.id)
      if (earlyReading) {
        setEarlyReading(earlyReading)
      } else {
        setEarlyReading(null)
      }
    }
  }, [earlyreadings, queries])

  useEffect(() => {
    if (earlyreadings) {
      const vowels = earlyreadings.filter((earlyreading) => earlyreading.category.name === 'Vowels')
      if (vowels) {
        setEarlyReadingVowels(vowels)
      } else {
        setEarlyReadingVowels([])
      }
      const phonics = earlyreadings.filter((earlyreading) => {
        if (earlyreading.category.name === 'Phonics') {
          return earlyreading
        }
      })
      if (phonics) {
        setEarlyReadingPhonics(phonics)
      } else {
        setEarlyReadingPhonics([])
      }
    }
  }, [earlyreadings])

  return (
    <>
      {queries.category && earlyReading ? (
        <EarlyReadingByCategory earlyReading={earlyReading} />
      ) : (
        <div className={classes.root}>
          <Card className={classes.cardRoot}>
            <Grid container className={classes.gridCard}>
              <Grid item md={12}>
                <div className={classes.titlesSection}>
                  <h3>Welcome to The Magazine Pre-Reader</h3>
                  <p>
                    This page is for students to practice their pronunciation with certain words. Check out the
                    different sections below!
                  </p>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12} className={classes.contentTitle}>
                <h2>Vowels</h2>
              </Grid>
              <Grid container spacing={3} className={classes.sectionArticles}>
                {earlyReadingVowels && earlyReadingVowels.length > 0 ? (
                  earlyReadingVowels.map((earlyreading) => (
                    <Grid item md={4} sm={6} xs={12} key={earlyreading._id}>
                      <Card
                        className={classes.articleCard}
                        onClick={() => [
                          history.push(
                            `/admin/earlyreading?id=${
                              earlyreading._id
                            }&category=${earlyreading.title.toLowerCase().trim()}`
                          ),
                        ]}
                      >
                        <CardActionArea>
                          <CardMedia
                            component='img'
                            height='200'
                            image={`${axios.defaults.baseURL}/${earlyreading.image}`}
                            alt='the-magazine'
                          />
                        </CardActionArea>
                        <CardContent>
                          <h5>{earlyreading.title}</h5>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <h5 className={classes.titleNotArticle}>Not Early Reading Vowels</h5>
                )}
              </Grid>
              <Grid item md={4} sm={4} xs={12} className={classes.contentTitle}>
                <h2>Phonics</h2>
              </Grid>
              <Grid container spacing={3} className={classes.sectionArticles}>
                {earlyReadingPhonics && earlyReadingPhonics.length > 0 ? (
                  earlyReadingPhonics.map((earlyreading) => (
                    <Grid item md={4} sm={6} xs={12} key={earlyreading._id}>
                      <Card
                        className={classes.articleCard}
                        onClick={() => [
                          history.push(
                            `/admin/earlyreading?id=${
                              earlyreading._id
                            }&category=${earlyreading.title.toLowerCase().trim()}`
                          ),
                        ]}
                      >
                        <CardActionArea>
                          <CardMedia
                            component='img'
                            height='200'
                            image={`${axios.defaults.baseURL}/${earlyreading.image}`}
                            alt='the-magazine'
                          />
                        </CardActionArea>
                        <CardContent>
                          <h5>{earlyreading.title}</h5>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <h5 className={classes.titleNotArticle}>Not Early Reading Phonics</h5>
                )}
              </Grid>
            </Grid>
          </Card>
        </div>
      )}
    </>
  )
}

export default EarlyReadingScreen
