import axios from 'axios'
import {
  CATEGORY_BY_TYPE_REQUEST,
  CATEGORY_BY_TYPE_SUCCESS,
  CATEGORY_BY_TYPE_FAIL,
} from 'redux/constants/categoryConstants'

export const getCategoriesByType = (type) => async (dispatch, getState) => {
  try {
    dispatch({ type: CATEGORY_BY_TYPE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Cache-Control': 'no-cache',
      },
    }

    console.log(type)
    const { data } = await axios.get(`/api/categories/type/${type}`, config)

    dispatch({ type: CATEGORY_BY_TYPE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CATEGORY_BY_TYPE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
