import { combineReducers } from 'redux'
import {
  refreshTokenReducer,
  userDeleteReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userUpdateMyInfoReducer,
  userUpdateReducer,
} from './userReducers'
import {
  logDeleteManyReducer,
  logDeleteReducer,
  logDetailsReducer,
  logListReducer,
  logRegisterReducer,
} from './logReducers'
import { sendEMailReducer } from './sendEMailReducers'
import { categoryByTypeReducer } from './categoryReducers'
import { magazineDetailsReducer, magazineListReducer } from './magazineReducers'
import { earlyReadingListReducer } from './earlyReadingReducers'
import { videoGrammarListReducer } from './videoGrammarReducers'
import { writingParticipationRegisterReducer } from './writingParticipationReducers'
import { classBlogListReducer, classBlogUpdateReducer } from './classBlogReducers'

export default combineReducers({
  categoryByType: categoryByTypeReducer,
  classBlogList: classBlogListReducer,
  classBlogUpdate: classBlogUpdateReducer,
  logDelete: logDeleteReducer,
  logDeleteMany: logDeleteManyReducer,
  logDetails: logDetailsReducer,
  logList: logListReducer,
  logRegister: logRegisterReducer,
  refreshToken: refreshTokenReducer,
  sendEMail: sendEMailReducer,
  userDelete: userDeleteReducer,
  userDetails: userDetailsReducer,
  userList: userListReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userUpdate: userUpdateReducer,
  userUpdateMyInfo: userUpdateMyInfoReducer,
  magazineList: magazineListReducer,
  magazineDetails: magazineDetailsReducer,
  earlyReadingList: earlyReadingListReducer,
  videoGrammarList: videoGrammarListReducer,
  writingParticipationRegister: writingParticipationRegisterReducer,
})
