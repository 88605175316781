const styles = {
  cardRoot: {
    width: '90%',
    margin: 'auto',
    padding: '20px 0px 40px 0px',
    '& h4': {
      fontSize: '32px',
      color: '#000d51',
      margin: '35px 0px 25px 0px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  gridCard: {
    padding: '0px 10px',
    textAlign: 'center',
  },
  mainTitle: {
    backgroundColor: '#009cf4',
    padding: '15px 0px',
    color: '#fff',
    fontSize: '22px',
  },
  contentVideo: {
    maxWidth: '90%',
  },
  btnDownload: {
    fontSize: '18px',
    '& svg': {
      height: '28px',
      width: '28px',
      marginLeft: '8px',
    },
  },
  '@media screen and (max-width: 580px)': {
    cardRoot: {
      width: '100%',
    },
  },
}

export default styles
