import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InputAdornment, makeStyles } from '@material-ui/core'
import { Person, Lock } from '@material-ui/icons'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardFooter from 'components/Card/CardFooter'
import { login } from 'redux/actions/userActions'
import styles from './styles/loginScreenStyles'

const useStyles = makeStyles(styles)

const LoginScreen = ({ location, history }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [cardAnimaton, setCardAnimation] = useState('cardHidden')

  const { loadingUserInfo, errorUserInfo, userInfo } = useSelector((state) => state.userLogin)

  const redirectInvestor = location.search ? location.search.split('=')[1] : '/admin/projects'
  const redirectAdmin = location.search ? location.search.split('=')[1] : '/admin/user-page'

  useEffect(() => {
    console.log(userInfo?.role)
    if (userInfo?.role === 'teacher') {
      history.push(redirectInvestor)
    } else if (userInfo?.role === 'admin' || userInfo?.role === 'superadministrator') {
      history.push(redirectAdmin)
    }
  }, [history, userInfo, redirectInvestor, redirectAdmin])

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation('')
    }, 200)

    return function cleanup() {
      window.clearTimeout(id)
    }
  })

  const loginHandler = (e) => {
    e.preventDefault()
    dispatch(login(username.trim(), password))
  }

  return (
    <div className={classes.container}>
      <GridContainer justifyContent='center'>
        <GridItem xs={12} sm={8} md={4}>
          <form onSubmit={loginHandler} autoComplete='off'>
            <Card loginRegister className={`${classes[cardAnimaton]} ${classes.cardRoot}`}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color='primary'>
                <h4 className={classes.cardTitle}>Access The Magazine</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText='Username'
                  id='username'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    value: username,
                    onChange: (e) => setUsername(e.target.value),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Person className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText='Password'
                  id='password'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'password',
                    value: password,
                    onChange: (e) => setPassword(e.target.value),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Lock className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    autoComplete: 'off',
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <GridContainer>
                  <GridItem xs={12}>
                    <Button type='submit' color='primary' size='lg' block>
                      {loadingUserInfo ? 'Login...' : 'Login'}
                    </Button>
                  </GridItem>
                  {errorUserInfo && (
                    <GridItem xs={12}>
                      <div className={classes.messageError}>{errorUserInfo}</div>
                    </GridItem>
                  )}
                </GridContainer>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default LoginScreen
