import { Card, Chip, Grid, Link, makeStyles } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { Stack } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import styles from '../styles/articleByMagazineStyle'
import { useHistory } from 'react-router-dom'
import CustomModal from 'components/Modal/CustomModal'

const useStyles = makeStyles(styles)

const ArticleByMagazine = ({ article }) => {
  const history = useHistory()
  const classes = useStyles()
  const magazineByArticle = useRef(null)

  const [openQuestion, setOpenQuestion] = useState(false)

  const scrollToRef = () => {
    magazineByArticle.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    const audioInfo = document.getElementById('myaudio')
    audioInfo.addEventListener('ended', () => {
      setOpenQuestion(true)
    })
  }, [])

  useEffect(() => {
    scrollToRef()
  }, [article])

  return (
    <div ref={magazineByArticle} className={classes.root}>
      <Card className={classes.cardRoot}>
        <Grid container spacing={1} className={classes.gridCard}>
          <Grid container spacing={2} className={classes.mainContent}>
            <Grid item md={12} sm={12} xs={12}>
              <div className={classes.barCard}></div>
            </Grid>
            <Grid item md={4} sm={12} xs={12} className={classes.contentArticle}>
              <p>#THEMAGAZINE.LIVE</p>
            </Grid>
            <Grid item md={4} sm={12} xs={12} className={classes.contentArticle}>
              <Link underline='none' onClick={() => history.goBack()}>
                <ArrowBack />
                <span>Back to summary</span>
              </Link>
            </Grid>
            <Grid item md={4} sm={12} xs={12} className={classes.contentArticle}>
              <p>{article.section}</p>
            </Grid>
          </Grid>
          <Grid item md={12} className={classes.imageArticle}>
            <img src={`${axios.defaults.baseURL}/${article.image}`} />
            <h2>{article.title}</h2>
          </Grid>
          <Grid item md={12}></Grid>
          {!openQuestion && (
            <Grid item md={12}>
              <p className={classes.textArticle}>{article.text}</p>
            </Grid>
          )}
          <Grid item md={12} sm={12} xs={12} className={classes.audioArticle}>
            <audio id='myaudio' controls>
              <source src={`${axios.defaults.baseURL}/${article.audio}`} type='audio/mp3' />
              Este es un elemento de audio no soportado por tu navegador, prueba con otro
            </audio>
          </Grid>
          <div className={classes.articleAuthor}>
            <div>
              <Stack>
                <Chip className={classes.articleCategory} label='Literature' color='primary' />
              </Stack>
            </div>
            <div className={classes.authorSection}>
              <img alt={article.author.country.name} src={`${axios.defaults.baseURL}${article.author.country.flag}`} />
              <h5>
                By {article.author.name} From {article.author.country.name}
              </h5>
            </div>
          </div>
        </Grid>
        <Grid item md={12}>
          <div className={classes.barCard}></div>
        </Grid>
      </Card>
      <CustomModal
        title={article.title}
        open={openQuestion}
        setOpen={setOpenQuestion}
        acceptText='Close'
        acceptHandler={() => setOpenQuestion(false)}
      ></CustomModal>
    </div>
  )
}

export default ArticleByMagazine
