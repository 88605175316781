import {
  CLASSBLOG_UPDATE_REQUEST,
  CLASSBLOG_UPDATE_SUCCESS,
  CLASSBLOG_UPDATE_FAIL,
  CLASSBLOG_UPDATE_RESET,
} from 'redux/constants/classBlogConstants'
import {
  CLASSBLOG_LIST_REQUEST,
  CLASSBLOG_LIST_SUCCESS,
  CLASSBLOG_LIST_FAIL,
  CLASSBLOG_LIST_RESET,
} from 'redux/constants/classBlogConstants'

export const classBlogListReducer = (state = { classblogs: [] }, action) => {
  switch (action.type) {
    case CLASSBLOG_LIST_REQUEST:
      return { loadingClassBlogList: true }
    case CLASSBLOG_LIST_SUCCESS:
      return {
        loadingClassBlogList: false,
        successClassBlogList: true,
        thatsIt: action.payload.length === 0 ? true : false,
        classblogs: action.payload,
      }
    case CLASSBLOG_LIST_FAIL:
      return { loadingClassBlogList: false, errorClassBlogList: action.payload }
    case CLASSBLOG_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const classBlogUpdateReducer = (state = { classblogUpdated: {} }, action) => {
  switch (action.type) {
    case CLASSBLOG_UPDATE_REQUEST:
      return { loadingClassBlogUpdate: true }
    case CLASSBLOG_UPDATE_SUCCESS:
      return {
        loadingClassBlogUpdate: false,
        successClassBlogUpdate: true,
        classblogUpdated: action.payload,
      }
    case CLASSBLOG_UPDATE_FAIL:
      return { loadingClassBlogUpdate: false, errorClassBlogUpdate: action.payload }
    case CLASSBLOG_UPDATE_RESET:
      return {}
    default:
      return state
  }
}
