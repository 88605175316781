import { Accordion, AccordionDetails, AccordionSummary, Card, Grid, makeStyles, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { useQueryParams } from 'shared/customHooks/useQueryParams'
import styles from './styles/faqsScreenStyle'

const teachersFaq = [
  {
    title: 'How do I access The Magazine?',
    text:
      'To access the platform, you need to click on the orange button in the top-right hand corner of the homepage. If you are already on the main page, you can find the access link in the top-right hand corner.',
  },
  {
    title: 'How can I create my groups and students?',
    text:
      'Within the teacher"s area, you will find the option "add student". There, you can create a new group or add students. Additionally, you can find a tutorial video on our YouTube channel.',
  },
  {
    title: 'Why can’t I create more students?',
    text:
      'The number of licenses purchased is the number assigned to your account. If you want to add more students, please contact The Magazine staff.',
  },
  {
    title: 'How can I personalize the name of my students?',
    text:
      'Within the teacher’s area, you can find a list of the different groups of your students. Here, you will have the option to modify names.',
  },
  {
    title: 'How do I see my groups and students?',
    text: 'In your personal teacher area, you have access to your list of students in the "Students lists" section.',
  },
  {
    title: 'How can I send articles to my students?',
    text:
      'Within your personal teacher area, find the "Students lists" section to send messages to the whole group or a personal message to an individual student.',
  },
  {
    title: 'How can I send grammar exercises to my students?',
    text:
      'In your personal teacher area, find the "Grammar" section. Here, you can select by class or level to find the appropriate grammar exercises.',
  },
  {
    title: 'Where can I find the finished exercises of my students?',
    text:
      'Once you enter the grammar exercises section, you can see if the exercise has been completed and sent. If not, in "view" you can see the progress and result of each exercise.',
  },
  {
    title: 'I lost my password, how can I recover it?',
    text:
      'If you are a teacher and you have lost or do not remember your password, you should contact The Magazine tam to have your password reset.',
  },
  {
    title: 'Why do I see the texts in Spanish?',
    text:
      'It is likely that your browser has automatic translation activated since The Magazine articles are in English.',
  },
  {
    title: 'How can I send videos for the VideoBlog and ClassBlog?',
    text: 'Please contact The Magazine team to send a video.',
  },
]

const studentsFaq = [
  {
    title: 'How do I access The Magazine?',
    text:
      'To access the platform, you need to click on the orange button in the top-right hand corner of the homepage. If you are already on the main page, you can find the access link in the top-right hand corner.',
  },
  {
    title: 'Where can I see the messages that my teacher sends me?',
    text:
      'In my personal student area, find the blue box titled "Inbox". There you can check all your messages sent by the teacher.',
  },
  {
    title: 'Where do I see the grammar exercises that my teacher has sent me?',
    text:
      'When accessing the student’s personal area, you will see a large green box titled "Grammar". The box also indicates when you have pending exercises.',
  },
  {
    title: 'I lost my password; how can I recover it?',
    text: 'As a student, you must contact your teacher to recover your password.',
  },
  {
    title: 'Where can I see my score total?',
    text:
      'In the student area, the total points appear in the orange box. Also, if you select an individual publication, you can see the points per publication.',
  },
  {
    title: 'Why don’t my stars add up?',
    text: 'Double check you are in the correct month. Stars appear with each publication.',
  },
  {
    title: 'Why do I see the texts in Spanish?',
    text:
      'It is likely that your browser has automatic translation activated since The Magazine articles are in English.',
  },
  {
    title: 'How can I participate in the Writing Contest?',
    text: 'In the Writing Area, we can find the current version of the "Writing Contest" and past winners.',
  },
  {
    title: 'How can I send the videos for the VideoBlog and the ClassBlog?',
    text: 'Please contact The Magazine team to send a video.',
  },
]

const useStyles = makeStyles(styles)

const FaqsScreen = () => {
  const classes = useStyles()
  const queries = useQueryParams()
  return (
    <div className={classes.root}>
      <Card className={classes.cardRoot}>
        <Grid container className={classes.gridCard}>
          {queries.teacher ? (
            <>
              <Grid item md={12}>
                <Typography variant='h4'>Frequently asked questions from teachers</Typography>
              </Grid>
              {teachersFaq.map((teacher) => (
                <Grid item md={12}>
                  <Accordion className={classes.accordionSection}>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
                      <Typography variant='h5'>{teacher.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.contentText}>{teacher.text}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </>
          ) : (
            <>
              <Grid item md={12}>
                <Typography variant='h4'>Frequently asked questions from students</Typography>
              </Grid>
              <Grid item md={12}>
                {studentsFaq.map((student) => (
                  <Accordion className={classes.accordionSection}>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
                      <Typography variant='h5'>{student.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.contentText}>{student.text}</Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </Card>
    </div>
  )
}

export default FaqsScreen
