import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { makeStyles } from '@material-ui/core'
import { PermIdentity } from '@material-ui/icons'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Clearfix from 'components/Clearfix/Clearfix'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardAvatar from 'components/Card/CardAvatar'
import UserProfileInputs from './components/UserProfileInputs'
import ChangePasswordAlert from './components/ChangePasswordAlert'
import role from 'config/roles/roles'
import { complexPasswordValidation } from 'shared/validators/inputValidators'
import { matchRoleName } from 'shared/matchData/matchData'
import { userUpdateMyInfo } from 'redux/actions/userActions'
import { USER_UPDATE_MY_INFO_RESET } from 'redux/constants/userConstants'
import { matchGender } from 'shared/matchData/matchData'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles'

const useStyles = makeStyles(styles)

const UserProfileScreen = ({ history }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { userInfo } = useSelector((state) => state.userLogin)
  const [changePasswordAlert, setChangePasswordAlert] = useState(false)
  const [userInformation, setUserInfo] = useState({ ...userInfo, password: '' })
  const [errorPasswordValidation, setErrorPasswordValidation] = useState('')

  const { loadingUpdateMyInfo, successUpdateMyInfo, errorUpdateMyInfo } = useSelector((state) => state.userUpdateMyInfo)

  useEffect(() => {
    if (successUpdateMyInfo) {
      setUserInfo({ ...userInfo, password: '' })
      setErrorPasswordValidation('')
      setTimeout(() => {
        dispatch({ type: USER_UPDATE_MY_INFO_RESET })
      }, 2000)
    }
  }, [dispatch, successUpdateMyInfo, userInfo])
  useEffect(() => {
    if (!userInformation) {
      history.push('/')
    }
  }, [])
  useEffect(() => {
    if (userInfo?.isDefaultPassword) {
      setChangePasswordAlert(true)
    }
    return () => setChangePasswordAlert(false)
  }, [userInfo])

  const updateProfileHandler = (e) => {
    e.preventDefault()
    if (userInformation.password && !complexPasswordValidation(userInformation.password)) {
      return setErrorPasswordValidation('The password does not meet the minimum conditions')
    }
    const userData = {
      _id: userInformation._id,
      name: userInformation.name,
      lastname: userInformation.lastname,
      phone: userInformation.phone,
      email: userInformation.email,
      password: userInformation.password,
      gender: matchGender(userInformation.gender),
      address: userInformation.address ?? userInformation.address,
    }
    dispatch(userUpdateMyInfo(userData))
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} style={{ margin: 'auto' }}>
          <Card profile>
            <CardAvatar profile round={true}>
              <a href='#pablo' onClick={(e) => e.preventDefault()}>
                <img src={`${axios.defaults.baseURL}${userInfo?.avatar}`} alt='...' />
              </a>
            </CardAvatar>
            <CardBody profile>
              {['none'].includes(userInfo?.personType) && (
                <h6 className={classes.cardCategory}>
                  Rol ·{' '}
                  {userInfo.role === role.SUPER_ROLE
                    ? 'Super Admin'
                    : userInfo.role === role.ADMIN_ROLE
                    ? 'Admin'
                    : userInfo.role === role.EDITOR_ROLE
                    ? 'Editor'
                    : userInfo.role === role.INVESTOR_ROLE
                    ? 'Inversor'
                    : 'No Role'}
                </h6>
              )}
              <h4 className={classes.cardTitle}>
                {userInfo?.name} {userInfo?.lastname}
              </h4>
              <p className={classes.description}>Phone · {userInfo?.phone}</p>
              <p className={classes.description}>Email · {userInfo?.email}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={11} md={10} lg={8} style={{ margin: 'auto' }}>
          <Card>
            <CardHeader color='primary' icon>
              <CardIcon color='primary' style={{ color: '#fff' }}>
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Update Profile - <small>{matchRoleName(userInformation?.role)}</small>
              </h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={updateProfileHandler}>
                <GridContainer>
                  <UserProfileInputs setUserInfo={setUserInfo} userInformation={userInformation} />
                  {errorUpdateMyInfo && (
                    <GridItem xs={12} sm={12}>
                      <SnackbarContent message={errorUpdateMyInfo} color='danger' />
                    </GridItem>
                  )}
                </GridContainer>{' '}
                {errorPasswordValidation && !complexPasswordValidation(userInformation.password) && (
                  <GridContainer>
                    <GridItem xs={12}>
                      <SnackbarContent message={errorPasswordValidation} color='danger' />
                    </GridItem>
                  </GridContainer>
                )}
                <Button
                  type='submit'
                  color={successUpdateMyInfo ? 'success' : 'primary'}
                  className={classes.updateProfileButton}
                >
                  {loadingUpdateMyInfo ? 'Updating...' : successUpdateMyInfo ? 'Updated User' : 'Update Profile'}
                </Button>
                <Clearfix />
              </form>
            </CardBody>
          </Card>
        </GridItem>
        {changePasswordAlert ? (
          <ChangePasswordAlert
            changePasswordAlert={changePasswordAlert}
            setChangePasswordAlert={setChangePasswordAlert}
          />
        ) : (
          <></>
        )}
      </GridContainer>
    </div>
  )
}

export default UserProfileScreen
