const styles = {
  cardRoot: {
    width: '90%',
    margin: 'auto',
    padding: '20px 0px 40px 0px',
    '& h4': {
      fontSize: '32px',
      color: '#000d51',
      margin: '35px 0px 25px 0px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  gridCard: {
    padding: '0px 30px',
    textAlign: 'center',
  },
  mainTitle: {
    borderRadius: '25px',
    backgroundColor: '#009cf4',
    padding: '15px 0px',
    color: '#fff',
    fontSize: '22px',
    margin: '20px 0px',
  },
  contentVideo: {
    maxWidth: '90%',
    borderRadius: '5px',
    boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
  },
  voteProject: {
    color: 'red',
    cursor: 'pointer',
  },
  actionLike: {
    textAlign: 'center',
    fontSize: '15px',
    '& svg': {
      height: '20px',
      width: '20px',
      marginBottom: '5px',
      marginLeft: '5px',
      marginRight: '5px',
    },
    '& span': {
      marginTop: '2px',
    },
  },
  notVoteProject: {
    color: 'gray',
    cursor: 'pointer',
  },
}

export default styles
