import {
  EARLY_READING_LIST_REQUEST,
  EARLY_READING_LIST_SUCCESS,
  EARLY_READING_LIST_FAIL,
  EARLY_READING_LIST_RESET,
} from 'redux/constants/earlyReadingConstants'

export const earlyReadingListReducer = (state = { earlyreadings: [] }, action) => {
  switch (action.type) {
    case EARLY_READING_LIST_REQUEST:
      return { loadingEarlyReadingList: true }
    case EARLY_READING_LIST_SUCCESS:
      return {
        loadingEarlyReadingList: false,
        successEarlyReadingList: true,
        thatsIt: action.payload.length === 0 ? true : false,
        earlyreadings: action.payload,
      }
    case EARLY_READING_LIST_FAIL:
      return { loadingEarlyReadingList: false, errorEarlyReadingList: action.payload }
    case EARLY_READING_LIST_RESET:
      return {}
    default:
      return state
  }
}
