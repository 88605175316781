import { makeStyles } from '@material-ui/core'
import { CircularProgress } from '@mui/material'
import classnames from 'classnames'
import styles from './styles/loaderStyles'

const useStyles = makeStyles(styles)
const Loader = () => {
  const classes = useStyles()
  return (
    <div className={classes.loaderWrapper}>
      <div className={classes.loader}>
        <div className={classnames(classes.loader, classes.loaderInner)}></div>
      </div>
    </div>
  )
}

export default Loader
