import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Card, Grid, makeStyles } from '@material-ui/core'
import { getVideoGrammars } from 'redux/actions/videoGrammarActions'
import styles from '../styles/videoGrammarLevelsStyle'

const useStyles = makeStyles(styles)

const VideoGrammarLevels = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const [videoGrammar, setVideoGrammar] = useState(null)

  const { videogrammars } = useSelector((state) => state.videoGrammarList)

  useEffect(() => {
    dispatch(getVideoGrammars())
  }, [dispatch])

  const handleSubmit = (level) => {
    if (videogrammars) {
      const videogrammar = videogrammars.find((videogrammar) => videogrammar.level === level)
      if (videogrammar) {
        history.push(`/admin/videogrammar?clauseId=${videogrammar._id}&level=${videogrammar.level}`)
      } else {
        history.push(`/admin/videogrammar`)
      }
      setVideoGrammar(videogrammar)
    }
  }

  return (
    <Card>
      <Grid container className={classes.gridCard}>
        <Grid item md={12} sm={12} xs={12} className={classes.mainTitle}>
          Level
        </Grid>
        {[1, 2, 3, 4, 5, 6, 7].map((level, index) => (
          <Grid key={index} item md={12} sm={12} xs={12}>
            <Button className={classes.levelSection} onClick={() => [handleSubmit(level)]}>
              Level {level}
            </Button>
          </Grid>
        ))}
        <Grid item md={12} sm={12} xs={12}>
          <Button className={classes.levelSection} onClick={() => [history.push(`/admin/videogrammar`)]}>
            All
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}

export default VideoGrammarLevels
