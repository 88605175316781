export const LOG_REGISTER_REQUEST = 'LOG_REGISTER_REQUEST'
export const LOG_REGISTER_SUCCESS = 'LOG_REGISTER_SUCCESS'
export const LOG_REGISTER_FAIL = 'LOG_REGISTER_FAIL'
export const LOG_REGISTER_RESET = 'LOG_REGISTER_RESET'

export const LOG_LIST_REQUEST = 'LOG_LIST_REQUEST'
export const LOG_LIST_SUCCESS = 'LOG_LIST_SUCCESS'
export const LOG_LIST_FAIL = 'LOG_LIST_FAIL'
export const LOG_LIST_RESET = 'LOG_LIST_RESET'

export const LOG_DETAILS_REQUEST = 'LOG_DETAILS_REQUEST'
export const LOG_DETAILS_SUCCESS = 'LOG_DETAILS_SUCCESS'
export const LOG_DETAILS_FAIL = 'LOG_DETAILS_FAIL'
export const LOG_DETAILS_RESET = 'LOG_DETAILS_RESET'

export const LOG_DELETE_REQUEST = 'LOG_DELETE_REQUEST'
export const LOG_DELETE_SUCCESS = 'LOG_DELETE_SUCCESS'
export const LOG_DELETE_FAIL = 'LOG_DELETE_FAIL'
export const LOG_DELETE_RESET = 'LOG_DELETE_RESET'

export const LOG_DELETE_MANY_REQUEST = 'LOG_DELETE_MANY_REQUEST'
export const LOG_DELETE_MANY_SUCCESS = 'LOG_DELETE_MANY_SUCCESS'
export const LOG_DELETE_MANY_FAIL = 'LOG_DELETE_MANY_FAIL'
export const LOG_DELETE_MANY_RESET = 'LOG_DELETE_MANY_RESET'
