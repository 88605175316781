const styles = {
  cardRoot: {
    width: '90%',
    margin: 'auto',
    padding: '20px 0px 40px 0px',
    '& h4': {
      fontSize: '32px',
      color: '#000d51',
      margin: '35px 0px 25px 0px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  gridCard: {
    padding: '0px 20px',
  },
  titlesSection: {
    textAlign: 'center',
    '& h1': {
      fontWeight: 'bold',
      fontSize: '28px',
      color: '#ff6600',
    },
    '& h3': {
      fontWeight: 'bold',
      fontSize: '32px',
      color: '#000d51',
    },
    '& p': {
      fontSize: '18px',
      margin: '20px 0px',
    },
  },
  sectionCategories: {
    textAlign: 'center',
    '& button': {
      padding: 0,
      borderRadius: '15px',
      '& img': {
        boxShadow: '0px 9px 18px rgba(0, 0, 0, 0.12), 0px 5px 7px rgba(0, 0, 0, 0.5)',
        maxWidth: '100%',
        height: '90px',
        borderRadius: '15px',
      },
    },
  },
  buttonConversation: {
    padding: 0,
    borderRadius: '15px',
    width: '100%',
    '& div': {
      boxShadow: '0px 9px 18px rgba(0, 0, 0, 0.12), 0px 5px 7px rgba(0, 0, 0, 0.5)',
      height: '100px',
      width: '100%',
      borderRadius: '15px',
      textAlign: 'end',
      padding: '5px 20px 0px 0px',
      '& span': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '28px',
        textTransform: 'initial',
      },
    },
  },
  activitieImage: {
    backgroundImage: 'url(/assets/img/podcasts/conversations/backgroundActivity.png)',
  },
  schoolImage: {
    backgroundImage: 'url(/assets/img/podcasts/conversations/backgroundSchool.png)',
  },
  streetImage: {
    backgroundImage: 'url(/assets/img/podcasts/conversations/backgroundStreet.png)',
  },
  '@media screen and (max-width: 580px)': {
    cardRoot: {
      width: '100%',
    },
    sectionCategories: {
      '& button': {
        '& img': {
          height: '60px',
        },
      },
    },
  },
}

export default styles
