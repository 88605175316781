import { useEffect } from 'react'
import axios from 'axios'
import { Card, Grid, makeStyles, Paper } from '@material-ui/core'
import { PictureAsPdf } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import VideoGrammarLevels from './VideoGrammarLevels'
import styles from '../styles/videoGrammarByLevelStyle'

const useStyles = makeStyles(styles)

const VideoGrammarByLevel = ({ videoGrammar }) => {
  const classes = useStyles()

  useEffect(() => {
    if (videoGrammar) {
      const video = document.querySelector('video')
      video.load()
    }
  })

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item md={10} sm={10} xs={12}>
          <Card className={classes.cardRoot}>
            <Grid container spacing={4} className={classes.gridCard}>
              <Grid item md={12} sm={12} xs={12}>
                <Paper elevation={3}>
                  <div className={classes.mainTitle}>
                    {videoGrammar.title} - Level {videoGrammar.level}
                  </div>
                </Paper>
              </Grid>
              <Grid item md={12}>
                <video
                  poster={`${axios.defaults.baseURL}/${videoGrammar.image}`}
                  className={classes.contentVideo}
                  controls
                >
                  <source src={`${axios.defaults.baseURL}/${videoGrammar.video}`} type='audio/mp4' />
                  Este es un elemento de audio no soportado por tu navegador, prueba con otro
                </video>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Button
                  className={classes.btnDownload}
                  color='primary'
                  onClick={() => window.open(`${axios.defaults.baseURL}/${videoGrammar.clause}`)}
                >
                  Download PDF
                  <PictureAsPdf />
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item md={2} sm={2} xs={12}>
          <VideoGrammarLevels />
        </Grid>
      </Grid>
    </div>
  )
}

export default VideoGrammarByLevel
