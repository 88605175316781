import { Card, Grid, makeStyles, Paper } from '@material-ui/core'
import styles from './styles/writingWinnersScreenStyles'

const useStyles = makeStyles(styles)
const WritingWinnersScreen = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Card className={classes.cardRoot}>
        <Grid container className={classes.gridCard}>
          <Grid item md={12} sm={12} xs={12}>
            <Paper className={classes.mainTitle} elevation={3}>
              <div>And the winners of the March writing contest are…</div>
            </Paper>
          </Grid>
          <Grid item md={12}>
            <h2>1st Lucía Llaca Aguilar - 1º ESO Instituto México de Puebla Campus San Pedro</h2>
          </Grid>
          <Grid item md={12} xs={12}>
            <img className={classes.cardImg} src='/assets/img/1ganador-marzo2021.jpg' />
          </Grid>
          <Grid item md={12}>
            <p className={classes.cardText}>
              Hello! My name is Lucia; I am from Mexico and today I will talk to you about my biggest goal in my life.
              It’s something we can all do; if I have the opportunity, I will surely do it. I love reading, it is one of
              my favorite hobbies, and I want other people to do and feel the same. I stay hours reading at night,
              sometimes I stay late at night reading because time passes very quickly, for example: I am reading at 9
              p.m. and when I realize it, it is already 10:30 p.m.! Also reading at night helps you to sleep better. But
              that is not the point, my biggest goal is that I want to make campaigns to invite people to read, so that
              they discover all the magic that can be in a single book. And with this, everyone can read and write. But
              not only will you know how to read and write well, you will learn new words and speak better. These are
              some of the reasons why I would like to do the reading campaigns. Don't you like to read? Well, give it a
              try, even if you don't like it very much at first. You will see that, when you open the book, you will
              enter a magical and wonderful world. This is what I want to do, encourage people to read. Do not forget,
              doors and books are the same, you open them and go to other worlds. Encourage yourself to read!
            </p>
          </Grid>
          <Grid item md={12}>
            <div className={classes.authorSection}>
              <img alt={'country'} src={`/assets/img/ve.svg`} />
              <h5>By Abraham From Venezuela</h5>
            </div>
            <hr />
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

export default WritingWinnersScreen
