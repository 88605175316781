const styles = {
  cardRoot: {
    maxWidth: '90%',
    margin: 'auto',
    padding: '20px',
  },
  gridCard: {
    width: '100%',
    textAlign: 'center',
  },
  mainImage: {
    width: '100%',
  },
  sectionArticles: {
    margin: 'auto',
  },
  articleCard: {
    cursor: 'pointer',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  contentTitle: {
    textAlign: 'start',
  },
  contentCategory: {
    justifyContent: 'start',
    flexWrap: 'wrap',
  },
  category: {
    margin: '5px',
  },
  titleNotArticle: {
    textAlign: 'center',
    width: '100%',
  },
  '@media screen and (max-width: 580px)': {
    cardRoot: {
      maxWidth: '100%',
    },
  },
}

export default styles
