import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Card, Grid, makeStyles } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import CustomModal from 'components/Modal/CustomModal'
import styles from '../styles/earlyReadingByCategoryStyle'

const useStyles = makeStyles(styles)

const EarlyReadingByCategory = ({ earlyReading }) => {
  const classes = useStyles()
  const history = useHistory()

  const [open, setOpen] = useState(false)
  const [reading, setReading] = useState(null)
  const [video, setVideo] = useState('')

  useEffect(() => {
    if (reading && earlyReading) {
      const earlyreading = earlyReading.readings.find((data) => data._id === reading._id)
      setVideo(earlyreading.video)
    }
  }, [reading, earlyReading])

  return (
    <div>
      <Card className={classes.cardRoot}>
        <Grid container className={classes.gridCard}>
          <Grid item md={12} sm={12} xs={12}>
            <div className={classes.contentTitle}>
              <h2>Blends And Diagraphs</h2>
            </div>
          </Grid>
          <Grid item md={12}>
            <Grid container className={classes.mainContent}>
              {earlyReading.readings &&
                earlyReading.readings.map((reading) => (
                  <Grid md={4} className={classes.contentSection} onClick={() => [setOpen(true), setReading(reading)]}>
                    <div className={classes.sectionReading}>
                      <span>{reading.digraph}</span>
                    </div>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item md={12} className={classes.contentArticle}>
            <Button color='primary' onClick={() => history.goBack()}>
              <span>Back</span>
            </Button>
          </Grid>
        </Grid>
      </Card>
      <CustomModal title='Blens and Diagraphs' open={open} setOpen={setOpen} acceptHandler={() => setOpen(false)}>
        {video && (
          <Grid container>
            <Grid item md={12}>
              <video className={classes.videoContent} controls>
                <source src={`${axios.defaults.baseURL}/${video}`} type='audio/mp4' />
                Este es un elemento de audio no soportado por tu navegador, prueba con otro
              </video>
            </Grid>
          </Grid>
        )}
      </CustomModal>
    </div>
  )
}

export default EarlyReadingByCategory
