const styles = {
  cardRoot: {
    width: '80%',
    margin: 'auto',
    padding: '20px 0px 40px 0px',
    '& h4': {
      fontSize: '32px',
      color: '#000d51',
      margin: '35px 0px 25px 0px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  gridCard: {
    padding: '0px 30px',
    textAlign: 'center',
  },
  gridForm: {
    padding: '0px 30px',
  },
  mainTitle: {
    borderRadius: '25px',
    backgroundColor: '#009cf4',
    padding: '15px 0px',
    color: '#fff',
    fontSize: '22px',
  },
  areaField: {
    width: '100%',
  },
  areaLabel: {
    color: '#009cf4',
  },
  checkedIcon: {
    width: '20px',
    height: '20px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  uncheckedIcon: {
    width: '0px',
    height: '0px',
    padding: '9px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  checked: {
    color: '#9c27b0 !important',
  },
  checkRoot: {
    padding: '12px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',
    color: 'rgba(0, 0, 0, 0.26)',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    display: 'inline-flex',
    transition: '0.3s ease all',
    letterSpacing: 'unset',
  },
  labelRoot: {
    marginRight: '0px',
    marginBottom: 0,
  },
  btnLink: {
    fontSize: 12,
    fontWeight: '500',
    color: '#483d3d',
    margin: '15px 0',
    padding: 0,
    textTransform: 'none',
    '& span': {
      fontSize: 14,
    },
  },
  '@media screen and (max-width: 980px)': {
    cardRoot: {
      width: '95%',
    },
  },
  '@media screen and (max-width: 580px)': {
    cardRoot: {
      width: '100%',
    },
  },
}

export default styles
