import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Card, CardActionArea, CardContent, CardMedia, Chip, Grid, makeStyles, Paper } from '@material-ui/core'
import VideoGrammarByLevel from './components/VideoGrammarByLevel'
import VideoGrammarLevels from './components/VideoGrammarLevels'
import { useQueryParams } from 'shared/customHooks/useQueryParams'
import { getVideoGrammars } from 'redux/actions/videoGrammarActions'
import styles from './styles/videoGrammarScreenStyle'

const useStyles = makeStyles(styles)

const VideoGrammarScreen = () => {
  const classes = useStyles()
  const queries = useQueryParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const [videoGrammar, setVideoGrammar] = useState(null)
  const [videoGrammarOne, setVideoGrammarOne] = useState(null)
  const [videos, setVideos] = useState([])

  const { videogrammars } = useSelector((state) => state.videoGrammarList)

  useEffect(() => {
    dispatch(getVideoGrammars())
  }, [dispatch])

  useEffect(() => {
    if (videogrammars) {
      const videogrammar = videogrammars.find((videogrammar) => videogrammar.level === 1)
      setVideoGrammarOne(videogrammar)
      const videos = videogrammars.filter((videogrammar) => videogrammar.level !== 1)
      setVideos(videos)
    }
  }, [videogrammars])

  useEffect(() => {
    if (videogrammars) {
      const videogrammar = videogrammars.find((videogrammar) => videogrammar._id === queries.clauseId)
      if (videogrammar) {
        setVideoGrammar(videogrammar)
      } else {
        setVideoGrammar(null)
      }
    }
  }, [videogrammars, queries])

  return (
    <>
      {queries.clauseId && videoGrammar ? (
        <VideoGrammarByLevel videoGrammar={videoGrammar} />
      ) : (
        <div className={classes.root}>
          <Grid container>
            <Grid item md={10} sm={10} xs={12}>
              <Card className={classes.cardRoot}>
                <Grid container className={classes.gridCard}>
                  <Grid item md={12}>
                    <div className={classes.titlesSection}>
                      <h3>Welcome to The Magazine Video Grammar</h3>
                      <p>Check out our new grammar videos!!! Are you ready to learn?</p>
                    </div>
                  </Grid>
                  <Grid container spacing={3}>
                    {videoGrammarOne && (
                      <Grid item md={12} className={classes.gridCard}>
                        <Paper className={classes.mainTitle} elevation={3}>
                          {videoGrammarOne.title} - Level {videoGrammarOne.level}
                        </Paper>
                        <img
                          onClick={() => [
                            history.push(
                              `/admin/videogrammar?clauseId=${videoGrammarOne._id}&level=${videoGrammarOne.level}`
                            ),
                          ]}
                          className={classes.imgGrammarOne}
                          src={`${axios.defaults.baseURL}/${videoGrammarOne.image}`}
                        />
                      </Grid>
                    )}
                    {videos &&
                      videos.map((videogrammar) => (
                        <Grid key={videogrammar._id} item md={4} sm={6} xs={12}>
                          <Card
                            className={classes.articleCard}
                            onClick={() => [
                              history.push(
                                `/admin/videogrammar?clauseId=${videogrammar._id}&level=${videogrammar.level}`
                              ),
                            ]}
                          >
                            <CardActionArea>
                              <CardMedia
                                component='img'
                                height='180'
                                width='200'
                                image={`${axios.defaults.baseURL}/${videogrammar.image}`}
                                alt='the-magazine'
                              />
                            </CardActionArea>
                            <CardContent className={classes.cardContent}>
                              <h5>{videogrammar.title}</h5>
                              <Chip label={`Level ${videogrammar.level}`} color='primary' />
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <VideoGrammarLevels videogrammars={videogrammars} />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default VideoGrammarScreen
