import {
  WRITING_PARTICIPATION_REGISTER_REQUEST,
  WRITING_PARTICIPATION_REGISTER_SUCCESS,
  WRITING_PARTICIPATION_REGISTER_FAIL,
  WRITING_PARTICIPATION_REGISTER_RESET,
} from 'redux/constants/writingParticipationConstants'

export const writingParticipationRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case WRITING_PARTICIPATION_REGISTER_REQUEST:
      return { loadingWritingParticipationRegister: true }
    case WRITING_PARTICIPATION_REGISTER_SUCCESS:
      return {
        loadingWritingParticipationRegister: false,
        successWritingParticipationRegister: true,
        writingParticipation: action.payload,
      }
    case WRITING_PARTICIPATION_REGISTER_FAIL:
      return {
        loadingWritingParticipationRegister: false,
        errorWritingParticipationRegister: action.payload,
      }
    case WRITING_PARTICIPATION_REGISTER_RESET:
      return {}
    default:
      return state
  }
}
