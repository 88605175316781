import { compareAsc } from 'date-fns'
import { replaceStringToNum } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'

const orderByString = (paramA, paramB, paramString) => {
  let stringA
  let stringB
  switch (paramString) {
    case 'name':
      stringA = paramA.values.name.toLowerCase()
      stringB = paramB.values.name.toLowerCase()
      break
    case 'investor':
      stringA = paramA.values.investor.toLowerCase()
      stringB = paramB.values.investor.toLowerCase()
      break
    case 'projectAddress':
      stringA = paramA.values.projectAddress.toLowerCase()
      stringB = paramB.values.projectAddress.toLowerCase()
      break
    case 'businessName':
      stringA = paramA.values.businessName.toLowerCase()
      stringB = paramB.values.businessName.toLowerCase()
      break
  }
  if (stringA > stringB) return 1
  if (stringB > stringA) return -1
  return 0
}

const orderByEmail = (paramA, paramB) => {
  let emailA = paramA.values.email.toLowerCase()
  let emailB = paramB.values.email.toLowerCase()

  if (emailA > emailB) return 1
  if (emailB > emailA) return -1
  return 0
}

const orderByAmount = (paramA, paramB) => {
  let amountA = replaceStringToNum(paramA.values.amount)
  let amountB = replaceStringToNum(paramB.values.amount)

  if (amountA > amountB) return 1
  if (amountB > amountA) return -1
  return 0
}

const orderByDate = (paramA, paramB) => {
  let dateA = new Date(paramA)
  let dateB = new Date(paramB)

  return compareAsc(dateA, dateB)
}

export { orderByString, orderByEmail, orderByAmount, orderByDate }
