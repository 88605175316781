import axios from 'axios'
import {
  MAGAZINE_DETAILS_REQUEST,
  MAGAZINE_DETAILS_SUCCESS,
  MAGAZINE_DETAILS_FAIL,
} from 'redux/constants/magazineConstants'
import { MAGAZINE_LIST_REQUEST, MAGAZINE_LIST_SUCCESS, MAGAZINE_LIST_FAIL } from 'redux/constants/magazineConstants'

export const getMagazines = () => async (dispatch) => {
  try {
    dispatch({ type: MAGAZINE_LIST_REQUEST })

    const { data } = await axios.get(`/api/magazines`)

    dispatch({ type: MAGAZINE_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: MAGAZINE_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getMagazineById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MAGAZINE_DETAILS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/magazines/${id}`, config)

    dispatch({ type: MAGAZINE_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: MAGAZINE_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
