import { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { Button, Card, Grid, makeStyles } from '@material-ui/core'
import CustomModal from 'components/Modal/CustomModal'
import Loader from 'components/Loader/Loader'
import MagazineArticle from './components/MagazineArticle'
import MagazineLevel from './components/MagazineLevel'
import { useQueryParams } from 'shared/customHooks/useQueryParams'
import { getMagazines } from 'redux/actions/magazineActions'
import styles from './styles/magazinesScreenStyle'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(styles)

const Magazines = ({ history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { level } = useQueryParams()
  const location = useLocation()

  const [open, setOpen] = useState(false)
  const [magazine, setMagazine] = useState(null)
  const magazineRef = useRef(null)

  const { loadingMagazineList, magazines } = useSelector((state) => state.magazineList)

  const scrollToRef = () => {
    magazineRef.current?.scrollIntoView({ behavior: 'smooth' })
    magazineRef.current?.scrollTo(0, 0)
  }

  useEffect(() => {
    dispatch(getMagazines())
  }, [dispatch])

  useEffect(() => {
    scrollToRef()
  }, [])

  return (
    <>
      <div ref={magazineRef} className={classes.root}>
        {loadingMagazineList ? (
          <Loader />
        ) : level ? (
          <MagazineLevel history={history} />
        ) : (
          <Card className={classes.cardRoot}>
            <Grid container className={classes.gridCard}>
              {magazines &&
                magazines.map((magazine) => (
                  <Grid item md={4} sm={6} xs={12} key={magazine._id}>
                    <h2 className={classes.titleGrid}>{magazine.name}</h2>
                    <Button className={classes.buttonGrid} onClick={() => [setOpen(true), setMagazine(magazine)]}>
                      <img className={classes.imageButton} src={`${axios.defaults.baseURL}/${magazine.coverImage}`} />
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </Card>
        )}
      </div>
      <CustomModal
        title='Our level are classified according to CEFR and Cambridge exams'
        open={open}
        setOpen={setOpen}
        acceptText='Close'
        acceptHandler={() => setOpen(false)}
      >
        {magazine && <MagazineArticle history={history} magazine={magazine} setOpen={setOpen} />}
      </CustomModal>
    </>
  )
}

export default Magazines
