import {
  MAGAZINE_DETAILS_RESET,
  MAGAZINE_DETAILS_SUCCESS,
  MAGAZINE_DETAILS_FAIL,
  MAGAZINE_DETAILS_REQUEST,
} from 'redux/constants/magazineConstants'
import {
  MAGAZINE_LIST_REQUEST,
  MAGAZINE_LIST_SUCCESS,
  MAGAZINE_LIST_FAIL,
  MAGAZINE_LIST_RESET,
} from 'redux/constants/magazineConstants'

export const magazineListReducer = (state = { magazines: [] }, action) => {
  switch (action.type) {
    case MAGAZINE_LIST_REQUEST:
      return { loadingMagazineList: true }
    case MAGAZINE_LIST_SUCCESS:
      return {
        loadingMagazineList: false,
        successMagazineList: true,
        thatsIt: action.payload.length === 0 ? true : false,
        magazines: action.payload,
      }
    case MAGAZINE_LIST_FAIL:
      return { loadingMagazineList: false, errorMagazineList: action.payload }
    case MAGAZINE_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const magazineDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case MAGAZINE_DETAILS_REQUEST:
      return { loadingMagazineDetails: true }
    case MAGAZINE_DETAILS_SUCCESS:
      return { loadingMagazineDetails: false, magazine: action.payload }
    case MAGAZINE_DETAILS_FAIL:
      return { loadingMagazineDetails: false, errorMagazineDetails: action.payload }
    case MAGAZINE_DETAILS_RESET:
      return {}
    default:
      return state
  }
}
