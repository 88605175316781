import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import role from 'config/roles/roles'

const UserInvestorProfileInputs = ({ setUserInfo, userInformation }) => {
  return (
    <GridItem xs={12}>
      <GridContainer style={{ marginBottom: '26px' }}>
        <GridItem xs={12}>
          <CustomInput
            labelText={'Name *'}
            id='name'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInformation.name,
              onChange: (e) => setUserInfo({ ...userInformation, name: e.target.value }),
              type: 'text',
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            labelText={'Lastname'}
            id='lastName'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInformation.lastname,
              onChange: (e) => setUserInfo({ ...userInformation, lastname: e.target.value }),
              type: 'text',
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            labelText={'Email *'}
            id='email'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInformation.email,
              onChange: (e) => setUserInfo({ ...userInformation, email: e.target.value }),
              type: 'email',
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            labelText='Phone *'
            id='phone'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInformation.phone,
              onChange: (e) => setUserInfo({ ...userInformation, phone: e.target.value }),
              type: 'text',
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            labelText='Address *'
            id='address'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInformation.address,
              onChange: (e) => setUserInfo({ ...userInformation, address: e.target.value }),
              type: 'text',
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            labelText='Password'
            id='passphrase'
            error={userInformation.role === role.INVESTOR_ROLE && userInformation.isDefaultPassword}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: userInformation.password || '',
              onChange: (e) => setUserInfo({ ...userInformation, password: e.target.value }),
              type: 'password',
              autoComplete: 'new-password',
            }}
          />
        </GridItem>
        <GridItem xs={12} style={{ fontSize: '11px' }}>
          * The password must have at least 8 characters, an uppercase, a lowercase and a number.
        </GridItem>
      </GridContainer>
    </GridItem>
  )
}

export default UserInvestorProfileInputs
