import axios from 'axios'
import {
  CLASSBLOG_UPDATE_REQUEST,
  CLASSBLOG_UPDATE_SUCCESS,
  CLASSBLOG_UPDATE_FAIL,
} from 'redux/constants/classBlogConstants'
import { CLASSBLOG_LIST_REQUEST, CLASSBLOG_LIST_SUCCESS, CLASSBLOG_LIST_FAIL } from 'redux/constants/classBlogConstants'

export const getClassBlogs = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CLASSBLOG_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/classblogs`, config)

    dispatch({ type: CLASSBLOG_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CLASSBLOG_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const classBlogUpdate = (classBlog) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLASSBLOG_UPDATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/classblogs/${classBlog._id}`, classBlog, config)

    dispatch({ type: CLASSBLOG_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CLASSBLOG_UPDATE_FAIL,
      payload:
        error.response && error.response.data.notFoundMessage ? error.response.data.notFoundMessage : error.message,
    })
  }
}
