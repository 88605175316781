import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_RESET,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,
  USER_REFRESH_TOKEN_REQUEST,
  USER_REFRESH_TOKEN_SUCCESS,
  USER_REFRESH_TOKEN_FAIL,
  USER_REFRESH_TOKEN_RESET,
  USER_UPDATE_MY_INFO_REQUEST,
  USER_UPDATE_MY_INFO_SUCCESS,
  USER_UPDATE_MY_INFO_FAIL,
  USER_UPDATE_MY_INFO_RESET,
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loadingUserInfo: true }
    case USER_LOGIN_SUCCESS:
      return { loadingUserInfo: false, userInfo: action.payload }
    case USER_LOGIN_FAIL:
      return { loadingUserInfo: false, errorUserInfo: action.payload }
    case USER_LOGOUT:
      localStorage.removeItem('setTM')
      return {}
    default:
      return state
  }
}

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loadingUserRegister: true }
    case USER_REGISTER_SUCCESS:
      return {
        loadingUserRegister: false,
        successUserRegister: true,
        user: action.payload,
      }
    case USER_REGISTER_FAIL:
      return {
        loadingUserRegister: false,
        errorUserRegister: action.payload,
      }
    case USER_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const refreshTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REFRESH_TOKEN_REQUEST:
      return { loadingRefreshToken: true }
    case USER_REFRESH_TOKEN_SUCCESS:
      return {
        loadingRefreshToken: false,
        successRefreshToken: true,
      }
    case USER_REFRESH_TOKEN_FAIL:
      return {
        loadingRefreshToken: false,
        errorRefreshToken: action.payload,
      }
    case USER_REFRESH_TOKEN_RESET:
      return {}
    default:
      return state
  }
}

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loadingUserList: true }
    case USER_LIST_SUCCESS:
      return {
        loadingUserList: false,
        successUserList: true,
        thatsIt: action.payload.length === 0 ? true : false,
        users: action.payload,
      }
    case USER_LIST_FAIL:
      return { loadingUserList: false, errorUserList: action.payload }
    case USER_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loadingUserDetails: true }
    case USER_DETAILS_SUCCESS:
      return {
        loadingUserDetails: false,
        successUserDetails: true,
        userDetail: action.payload,
      }
    case USER_DETAILS_FAIL:
      return {
        loadingUserDetails: false,
        errorUserDetails: action.payload,
      }
    case USER_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const userUpdateReducer = (state = { userUpdated: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loadingUserUpdate: true }
    case USER_UPDATE_SUCCESS:
      return {
        loadingUserUpdate: false,
        successUserUpdate: true,
        userUpdated: action.payload,
      }
    case USER_UPDATE_FAIL:
      return { loadingUserUpdate: false, errorUserUpdate: action.payload }
    case USER_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loadingUserDelete: true }
    case USER_DELETE_SUCCESS:
      return { loadingUserDelete: false, successUserDelete: true }
    case USER_DELETE_FAIL:
      return { loadingUserDelete: false, errorUserDelete: action.payload }
    case USER_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const userUpdateMyInfoReducer = (state = { userUpdateMyInfo: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_MY_INFO_REQUEST:
      return { loadingUpdateMyInfo: true }
    case USER_UPDATE_MY_INFO_SUCCESS:
      return {
        loadingUpdateMyInfo: false,
        successUpdateMyInfo: true,
        userUpdateMyInfo: action.payload,
      }
    case USER_UPDATE_MY_INFO_FAIL:
      return { loadingUpdateMyInfo: false, errorUpdateMyInfo: action.payload }
    case USER_UPDATE_MY_INFO_RESET:
      return {}
    default:
      return state
  }
}
