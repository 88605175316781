import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import axios from 'axios'
import cx from 'classnames'
import { Drawer, ListItem, ListItemText, Hidden, Collapse, Icon, makeStyles } from '@material-ui/core'
import List from '@mui/material/List'
import {
  Assignment,
  BorderColor,
  Class,
  Comment,
  ExitToApp,
  FormatListNumbered,
  ImportContacts,
  Inbox,
  ListSharp,
  NextWeek,
  Person,
  Share,
  VideoCall,
} from '@material-ui/icons'
import ListIcon from '@mui/icons-material/List'
import {
  AddBusiness,
  PersonAdd,
  HomeWork,
  AddCircle,
  AssignmentInd,
  AdminPanelSettings,
  EuroSymbol,
  ListAlt,
  SupervisedUserCircle,
  SettingsSuggest,
  Podcasts,
  AutoStories,
  VideoCameraBack,
} from '@mui/icons-material'
import EuroIcon from '@mui/icons-material/Euro'
import logoComplete from 'assets/img/logo.png'
import logoCollapse from 'assets/img/collapseLogo.png'
import SidebarWrapper from './components/SidebarWrapper'
import { useWindowsSize } from 'shared/customHooks/useWindowsSize'
import { activeRoute } from './functions/functions'
import { logout } from 'redux/actions/userActions'
import sidebarStyle from './styles/sidebarStyle'

const useStyles = makeStyles(sidebarStyle)

const Sidebar = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const windowsSize = useWindowsSize()
  const mainPanel = useRef()

  const [miniActive, setMiniActive] = useState(true)
  const [openAvatar, setOpenAvatar] = useState(false)
  const [openSpeaking, setOpenSpeaking] = useState(false)
  const [openListening, setOpenListening] = useState(false)
  const [openWriting, setOpenWriting] = useState(false)
  const [state, setState] = useState({})

  const [projectCollapse, setProjectCollapse] = useState(false)
  const [adminCollapse, setAdminCollapse] = useState(false)
  const [investorCollapse, setInvestorCollapse] = useState(false)
  const [investorProfile, setInvestorProfile] = useState(false)

  const { userInfo } = useSelector((state) => state.userLogin)

  const marketingPermissions = userInfo?.isMarketing
  const contabilidadPermissions = userInfo?.isContabilidad
  const managementPermissions = userInfo?.isManagement
  const investorRelationsPermissions = userInfo?.isInvestorRelations

  useEffect(() => {
    if (userInfo === null) {
      history.push('/')
    }
  }, [userInfo])
  useEffect(() => {
    setState(getCollapseStates(props.routes))
  }, [])
  useEffect(() => {
    if (windowsSize.width < 960 && props.open) {
      props.handleDrawerToggle(false)
    }
  }, [location.pathname])

  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {}
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        }
      }
      return null
    })
    return initialState
  }
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true
      } else if (location.pathname === routes[i].layout + routes[i].path) {
        return true
      }
    }
    return false
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    const { color, rtlActive } = props
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null
      }
      if (prop.path === '/user-page') {
        return null
      }
      if (prop.collapse) {
        var st = {}
        st[prop['state']] = !state[prop.state]
        const navLinkClasses =
          classes.itemLink +
          ' ' +
          cx({
            [' ' + classes.collapseActive]: getCollapseInitialState(prop.views),
          })
        const itemText =
          classes.itemText +
          ' ' +
          cx({
            [classes.itemTextMini]: props.miniActive && miniActive,
            [classes.itemTextMiniRTL]: rtlActive && props.miniActive && miniActive,
            [classes.itemTextRTL]: rtlActive,
          })
        const collapseItemText =
          classes.collapseItemText +
          ' ' +
          cx({
            [classes.collapseItemTextMini]: props.miniActive && miniActive,
            [classes.collapseItemTextMiniRTL]: rtlActive && props.miniActive && miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          })
        const itemIcon =
          classes.itemIcon +
          ' ' +
          cx({
            [classes.itemIconRTL]: rtlActive,
          })
        const caret =
          classes.caret +
          ' ' +
          cx({
            [classes.caretRTL]: rtlActive,
          })
        const collapseItemMini =
          classes.collapseItemMini +
          ' ' +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive,
          })
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={'#'}
              className={classes.navLinkClasses}
              onClick={(e) => {
                e.preventDefault()
                setState({ ...state, ...st })
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === 'string' ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>{rtlActive ? prop.rtlMini : prop.mini}</span>
              )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                secondary={<b className={caret + ' ' + (state[prop.state] ? classes.caretActive : '')} />}
                disableTypography={true}
                className={cx({ [itemText]: prop.icon !== undefined }, { [collapseItemText]: prop.icon === undefined })}
              />
            </NavLink>
            <Collapse in={state[prop.state]} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>{createLinks(prop.views)}</List>
            </Collapse>
          </ListItem>
        )
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        ' ' +
        cx({
          [' ' + classes[color]]: activeRoute(prop.layout + prop.path, location),
        })
      const collapseItemMini =
        classes.collapseItemMini +
        ' ' +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive,
        })
      const navLinkClasses =
        classes.itemLink +
        ' ' +
        cx({
          [' ' + classes[color]]: activeRoute(prop.layout + prop.path, location),
        })
      const itemText =
        classes.itemText +
        ' ' +
        cx({
          [classes.itemTextMini]: props.miniActive && miniActive,
          [classes.itemTextMiniRTL]: rtlActive && props.miniActive && miniActive,
          [classes.itemTextRTL]: rtlActive,
        })
      const collapseItemText =
        classes.collapseItemText +
        ' ' +
        cx({
          [classes.collapseItemTextMini]: props.miniActive && miniActive,
          [classes.collapseItemTextMiniRTL]: rtlActive && props.miniActive && miniActive,
          [classes.collapseItemTextRTL]: rtlActive,
        })
      const itemIcon =
        classes.itemIcon +
        ' ' +
        cx({
          [classes.itemIconRTL]: rtlActive,
        })
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === 'string' ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>{rtlActive ? prop.rtlMini : prop.mini}</span>
            )}
            <ListItemText
              primary={rtlActive ? prop.rtlName : prop.name}
              disableTypography={true}
              className={cx({ [itemText]: prop.icon !== undefined }, { [collapseItemText]: prop.icon === undefined })}
            />
          </NavLink>
        </ListItem>
      )
    })
  }
  const { logo, image, logoText, routes, bgColor, rtlActive } = props
  const itemText =
    classes.itemText +
    ' ' +
    cx({
      [classes.itemTextMini]: props.miniActive && miniActive,
      [classes.itemTextMiniRTL]: rtlActive && props.miniActive && miniActive,
      [classes.itemTextRTL]: rtlActive,
    })
  const collapseItemText =
    classes.collapseItemText +
    ' ' +
    cx({
      [classes.collapseItemTextMini]: props.miniActive && miniActive,
      [classes.collapseItemTextMiniRTL]: rtlActive && props.miniActive && miniActive,
      [classes.collapseItemTextRTL]: rtlActive,
    })
  const userWrapperClass =
    classes.user +
    ' ' +
    cx({
      [classes.whiteAfter]: bgColor === 'white',
    })
  const caret =
    classes.caret +
    ' ' +
    cx({
      [classes.caretRTL]: rtlActive,
    })
  const collapseItemMini =
    classes.collapseItemMini +
    ' ' +
    cx({
      [classes.collapseItemMiniRTL]: rtlActive,
    })
  const photo =
    classes.photo +
    ' ' +
    cx({
      [classes.photoRTL]: rtlActive,
      [classes.roundPhoto]: !['legal'].includes(userInfo?.personType),
    })
  var user = (
    <div className={userWrapperClass}>
      <div className={photo}>
        <img src={`${axios.defaults.baseURL}${userInfo?.avatar}`} className={classes.avatarImg} alt='...' />
      </div>
      <List className={classes.list}>
        <ListItem className={classes.item + ' ' + classes.userItem}>
          <NavLink
            to={'#'}
            className={classes.itemLink + ' ' + classes.userCollapseButton}
            onClick={() => setOpenAvatar(!openAvatar)}
          >
            <ListItemText
              primary={
                userInfo?.name.length > 21
                  ? userInfo?.name.slice(0, 21) + '...'
                  : `${userInfo?.name} ${userInfo?.lastName || ''}`
              }
              secondary={
                <b className={caret + ' ' + classes.userCaret + ' ' + (openAvatar ? classes.caretActive : '')} />
              }
              disableTypography={true}
              className={itemText + ' ' + classes.userItemText}
            />
          </NavLink>
          <Collapse in={openAvatar} unmountOnExit>
            <List className={classes.list + ' ' + classes.collapseList}>
              <ListItem className={classes.collapseItem}>
                <NavLink to='/admin/user-page' className={classes.itemLink + ' ' + classes.userCollapseLinks}>
                  <span className={collapseItemMini}>
                    <Person color='primary' />
                  </span>
                  <ListItemText primary='My Profile' disableTypography={true} className={collapseItemText} />
                </NavLink>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>
      </List>
    </div>
  )
  var links = (
    <List className={classes.list}>
      {userInfo?.role === 'investor' && (
        <>
          <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
            <NavLink to={'/admin/projects'} className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}>
              <Icon className={classes.itemIcon}>{<ListIcon />}</Icon>
              <ListItemText
                primary={'Proyectos para Invertir'}
                disableTypography={true}
                className={cx({ [itemText]: true }, { [collapseItemText]: false })}
              />
            </NavLink>
          </ListItem>

          <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
            <NavLink to={'/admin/my-investments'} className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}>
              <Icon className={classes.itemIcon}>{<EuroSymbol />}</Icon>
              <ListItemText
                primary={'Mis Inversiones'}
                disableTypography={true}
                className={cx({ [itemText]: true }, { [collapseItemText]: false })}
              />
            </NavLink>
          </ListItem>

          <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
            <NavLink
              to={'#!'}
              className={classes.itemLink + ' ' + classes.collapseItemLink}
              onClick={(e) => {
                e.preventDefault()
                setInvestorProfile(!investorProfile)
              }}
            >
              <span className={classes.collapseItemMini}>
                <AssignmentInd />
              </span>

              <ListItemText
                primary={'Administrar Perfiles'}
                secondary={
                  <b
                    className={
                      classes.caret + ' ' + classes.userCaret + ' ' + (investorProfile ? classes.caretActive : '')
                    }
                  />
                }
                disableTypography={true}
                className={cx({ [itemText]: false }, { [collapseItemText]: true })}
              />
            </NavLink>
            <Collapse in={investorProfile} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                <ListItem className={cx({ [itemText]: false }, { [collapseItemText]: true })}>
                  <NavLink
                    to={'/admin/investor-register'}
                    className={classes.itemLink + ' ' + classes.collapseItemLink}
                  >
                    <span className={classes.collapseItemMini}>
                      <AddCircle />
                    </span>
                    <ListItemText
                      primary='Registrar Perfil'
                      disableTypography={true}
                      className={cx({ [itemText]: false }, { [collapseItemText]: true })}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={cx({ [itemText]: false }, { [collapseItemText]: true })}>
                  <NavLink
                    to={'/admin/investor-profile-my-list'}
                    className={classes.itemLink + ' ' + classes.collapseItemLink}
                  >
                    <span className={classes.collapseItemMini}>
                      <ListAlt />
                    </span>
                    <ListItemText
                      primary='Lista de Perfiles'
                      disableTypography={true}
                      className={cx({ [itemText]: false }, { [collapseItemText]: true })}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </>
      )}

      {(managementPermissions || contabilidadPermissions || marketingPermissions) && (
        <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
          <NavLink
            to={'#!'}
            className={classes.itemLink + ' ' + classes.collapseItemLink}
            onClick={(e) => {
              e.preventDefault()
              setProjectCollapse(!projectCollapse)
            }}
          >
            <span className={classes.collapseItemMini}>
              <HomeWork />
            </span>

            <ListItemText
              primary={'Administrar Proyectos'}
              secondary={
                <b
                  className={
                    classes.caret + ' ' + classes.userCaret + ' ' + (projectCollapse ? classes.caretActive : '')
                  }
                />
              }
              disableTypography={true}
              className={cx({ [itemText]: false }, { [collapseItemText]: true })}
            />
          </NavLink>
          <Collapse in={projectCollapse} unmountOnExit>
            <List className={classes.list + ' ' + classes.collapseList}>
              <ListItem className={cx({ [itemText]: false }, { [collapseItemText]: true })}>
                <NavLink to={'/admin/list-projects'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
                  <span className={classes.collapseItemMini}>
                    <ListIcon />
                  </span>
                  <ListItemText
                    primary='Lista de Proyectos'
                    disableTypography={true}
                    className={cx({ [itemText]: false }, { [collapseItemText]: true })}
                  />
                </NavLink>
              </ListItem>
              {/* <ListItem className={cx({ [itemText]: false }, { [collapseItemText]: true })}>
                <NavLink to={'/admin/projects'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
                  <span className={classes.collapseItemMini}>
                    <ReceiptLongIcon />
                  </span>
                  <ListItemText
                    primary='Invertir'
                    disableTypography={true}
                    className={cx({ [itemText]: false }, { [collapseItemText]: true })}
                  />
                </NavLink>
              </ListItem> */}
              {(marketingPermissions || managementPermissions) && (
                <ListItem className={cx({ [itemText]: false }, { [collapseItemText]: true })}>
                  <NavLink to={'/admin/register-project'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
                    <span className={classes.collapseItemMini}>
                      <AddBusiness />
                    </span>
                    <ListItemText
                      primary='Registrar Proyecto'
                      disableTypography={true}
                      className={cx({ [itemText]: false }, { [collapseItemText]: true })}
                    />
                  </NavLink>
                </ListItem>
              )}

              {(contabilidadPermissions || managementPermissions) && (
                <ListItem className={cx({ [itemText]: false }, { [collapseItemText]: true })}>
                  <NavLink
                    to={'/admin/liquidate-project'}
                    className={classes.itemLink + ' ' + classes.collapseItemLink}
                  >
                    <span className={classes.collapseItemMini}>
                      <EuroIcon />
                    </span>
                    <ListItemText
                      primary='Liquidar Proyecto'
                      disableTypography={true}
                      className={cx({ [itemText]: false }, { [collapseItemText]: true })}
                    />
                  </NavLink>
                </ListItem>
              )}
            </List>
          </Collapse>
        </ListItem>
      )}
      {(managementPermissions || investorRelationsPermissions || contabilidadPermissions) && (
        <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
          <NavLink to={'/admin/investment-list'} className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}>
            <Icon className={classes.itemIcon}>{<AdminPanelSettings />}</Icon>
            <ListItemText
              primary={'Lista de Inversiones'}
              disableTypography={true}
              className={cx({ [itemText]: true }, { [collapseItemText]: false })}
            />
          </NavLink>
        </ListItem>
      )}
      {managementPermissions && (
        <ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
          <NavLink
            to={'#!'}
            className={classes.itemLink + ' ' + classes.collapseItemLink}
            onClick={(e) => {
              e.preventDefault()
              setAdminCollapse(!adminCollapse)
            }}
          >
            <span className={classes.collapseItemMini}>
              <SupervisedUserCircle />
            </span>

            <ListItemText
              primary={'Administradores'}
              secondary={
                <b
                  className={classes.caret + ' ' + classes.userCaret + ' ' + (adminCollapse ? classes.caretActive : '')}
                />
              }
              disableTypography={true}
              className={cx({ [itemText]: false }, { [collapseItemText]: true })}
            />
          </NavLink>
          <Collapse in={adminCollapse} unmountOnExit>
            <List className={classes.list + ' ' + classes.collapseList}>
              <ListItem className={cx({ [itemText]: false }, { [collapseItemText]: true })}>
                <NavLink to={'/admin/register-user'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
                  <span className={classes.collapseItemMini}>
                    <PersonAdd />
                  </span>
                  <ListItemText
                    primary='Registro de Administradores'
                    disableTypography={true}
                    className={cx({ [itemText]: false }, { [collapseItemText]: true })}
                  />
                </NavLink>
              </ListItem>
              <ListItem className={cx({ [itemText]: false }, { [collapseItemText]: true })}>
                <NavLink to={'/admin/user-list'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
                  <span className={classes.collapseItemMini}>
                    <ListIcon />
                  </span>
                  <ListItemText
                    primary='Lista de Administradores'
                    disableTypography={true}
                    className={cx({ [itemText]: false }, { [collapseItemText]: true })}
                  />
                </NavLink>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>
      )}
      <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
        <NavLink to='/' className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}>
          <span className={collapseItemMini}>
            <Comment color='primary' />
          </span>
          <ListItemText
            primary='Tutorial'
            disableTypography={true}
            className={cx({ [itemText]: true }, { [collapseItemText]: true })}
          />
        </NavLink>
      </ListItem>
      <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
        <NavLink to='/admin/inbox' className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}>
          <span className={collapseItemMini}>
            <Inbox color='error' />
          </span>
          <ListItemText
            primary='Inbox'
            disableTypography={true}
            className={cx({ [itemText]: true }, { [collapseItemText]: true })}
          />
        </NavLink>
      </ListItem>
      <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
        <NavLink to='/' className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}>
          <span className={collapseItemMini}>
            <FormatListNumbered color='secondary' />
          </span>
          <ListItemText
            primary='Student List'
            disableTypography={true}
            className={cx({ [itemText]: true }, { [collapseItemText]: true })}
          />
        </NavLink>
      </ListItem>
      <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
        <NavLink to='/' className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}>
          <span className={collapseItemMini}>
            <SettingsSuggest color='primary' />
          </span>
          <ListItemText
            primary='Suggestions'
            disableTypography={true}
            className={cx({ [itemText]: true }, { [collapseItemText]: true })}
          />
        </NavLink>
      </ListItem>
      <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
        <NavLink to='/' className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}>
          <span className={collapseItemMini}>
            <Share color='error' />
          </span>
          <ListItemText
            primary='Shared Resources'
            disableTypography={true}
            className={cx({ [itemText]: true }, { [collapseItemText]: true })}
          />
        </NavLink>
      </ListItem>
      <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
        <NavLink to='/' className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}>
          <span className={collapseItemMini}>
            <NextWeek color='secondary' />
          </span>
          <ListItemText
            primary='Coming Next Month'
            disableTypography={true}
            className={cx({ [itemText]: true }, { [collapseItemText]: true })}
          />
        </NavLink>
      </ListItem>
      <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
        <NavLink to='/admin/classblog' className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}>
          <span className={collapseItemMini}>
            <VideoCameraBack color='secondary' />
          </span>
          <ListItemText
            primary='ClassBlog'
            disableTypography={true}
            className={cx({ [itemText]: true }, { [collapseItemText]: true })}
          />
        </NavLink>
      </ListItem>
      <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
        <NavLink
          to={`${location.pathname + location.search}`}
          className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
          onClick={() => setOpenListening(!openListening)}
        >
          <span className={collapseItemMini}>
            <ListSharp color='error' />
          </span>
          <ListItemText
            primary='Listening'
            secondary={
              <b className={caret + ' ' + classes.userCaret + ' ' + (openAvatar ? classes.caretActive : '')} />
            }
            disableTypography={true}
            className={itemText + ' ' + classes.userItemText}
          />
        </NavLink>
        <Collapse in={openListening} unmountOnExit>
          <List className={classes.list + ' ' + classes.collapseList}>
            <ListItem className={classes.collapseItem}>
              <NavLink to='/admin/magazines' className={classes.itemLink + ' ' + classes.userCollapseLinks}>
                <span className={collapseItemMini}>
                  <ImportContacts color='primary' />
                </span>
                <ListItemText primary='Magazines' disableTypography={true} className={collapseItemText} />
              </NavLink>
              <NavLink to='/admin/podcasts' className={classes.itemLink + ' ' + classes.userCollapseLinks}>
                <span className={collapseItemMini}>
                  <Podcasts color='primary' />
                </span>
                <ListItemText primary='Podcasts' disableTypography={true} className={collapseItemText} />
              </NavLink>
              <NavLink to='/admin/videogrammar' className={classes.itemLink + ' ' + classes.userCollapseLinks}>
                <span className={collapseItemMini}>
                  <VideoCall color='primary' />
                </span>
                <ListItemText primary='Videogrammar' disableTypography={true} className={collapseItemText} />
              </NavLink>
              <NavLink to='/admin/earlyreading' className={classes.itemLink + ' ' + classes.userCollapseLinks}>
                <span className={collapseItemMini}>
                  <AutoStories color='primary' />
                </span>
                <ListItemText primary='Early Reading' disableTypography={true} className={collapseItemText} />
              </NavLink>
            </ListItem>
          </List>
        </Collapse>
      </ListItem>
      <ListItem className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}>
        <NavLink
          to={`${location.pathname + location.search}`}
          className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}
          onClick={() => setOpenWriting(!openWriting)}
        >
          <span className={collapseItemMini}>
            <BorderColor color='primary' />
          </span>
          <ListItemText
            primary='Writing'
            secondary={
              <b className={caret + ' ' + classes.userCaret + ' ' + (openAvatar ? classes.caretActive : '')} />
            }
            disableTypography={true}
            className={itemText + ' ' + classes.userItemText}
          />
        </NavLink>
        <Collapse in={openWriting} unmountOnExit>
          <List className={classes.list + ' ' + classes.collapseList}>
            <ListItem className={classes.collapseItem}>
              <NavLink to='/admin/writing/participate' className={classes.itemLink + ' ' + classes.userCollapseLinks}>
                <span className={collapseItemMini}>
                  <Assignment color='error' />
                </span>
                <ListItemText primary='Participate' disableTypography={true} className={collapseItemText} />
              </NavLink>
            </ListItem>
          </List>
        </Collapse>
      </ListItem>
      <ListItem
        className={cx({ [classes.item]: true }, { [classes.collapseItem]: true })}
        onClick={() => dispatch(logout())}
      >
        <NavLink to='/' className={`${classes.itemLink}` + ' ' + classes.collapseItemLink}>
          <span className={collapseItemMini}>
            <ExitToApp color='secondary' />
          </span>
          <ListItemText
            primary='Logout'
            disableTypography={true}
            className={cx({ [itemText]: true }, { [collapseItemText]: true })}
          />
        </NavLink>
      </ListItem>
    </List>
  )

  const logoNormal =
    classes.logoNormal +
    ' ' +
    cx({
      [classes.logoNormalSidebarMini]: props.miniActive && miniActive,
    })
  const logoMini = classes.logoMini
  const logoClasses =
    classes.logo +
    ' ' +
    cx({
      [classes.whiteAfter]: bgColor === 'white',
    })
  const brand = (
    <div className={logoClasses}>
      {props.miniActive ? (
        <a href='https://shareholders.chapnikandgiesen.com?ref=mdpr-sidebar' className={logoMini}>
          <img src={logoCollapse} alt='logo' className={classes.img} />
        </a>
      ) : (
        <a href='https://shareholders.chapnikandgiesen.com?ref=mdpr-sidebar'>
          <img src={logoComplete} alt='logo' className={classes.imgComplete} />
        </a>
      )}
    </div>
  )
  const brandComplete = (
    <div className={logoClasses}>
      <a href='https://shareholders.chapnikandgiesen.com?ref=mdpr-sidebar'>
        <img src={logoComplete} alt='logo' className={classes.imgComplete} />
      </a>
    </div>
  )
  const drawerPaper =
    classes.drawerPaper +
    ' ' +
    cx({
      [classes.drawerPaperMini]: props.miniActive && miniActive,
      [classes.drawerPaperRTL]: rtlActive,
    })
  const sidebarWrapper =
    classes.sidebarWrapper +
    ' ' +
    cx({
      [classes.drawerPaperMini]: props.miniActive && miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
    })
  return (
    <div ref={mainPanel}>
      <Hidden mdUp implementation='css'>
        <Drawer
          variant='temporary'
          anchor={rtlActive ? 'left' : 'right'}
          open={props.open}
          classes={{
            paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brandComplete}
          <SidebarWrapper className={sidebarWrapper} user={user} links={links} />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation='css'>
        <Drawer
          onMouseOut={() => setMiniActive(true)}
          anchor={rtlActive ? 'right' : 'left'}
          variant='permanent'
          open
          classes={{
            paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
          }}
        >
          {brand}
          <SidebarWrapper className={sidebarWrapper} user={user} links={links} />
        </Drawer>
      </Hidden>
    </div>
  )
}

Sidebar.defaultProps = {
  bgColor: 'blue',
}
Sidebar.propTypes = {
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'red', 'orange', 'green', 'blue', 'purple', 'rose']),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
}

export default Sidebar
