import { AccountBox, Create } from '@material-ui/icons'
import UserProfileScreen from 'screens/control-panel/UserProfileScreen/UserProfileScreen'
import PodcastsScreen from 'screens/control-panel/PodcastsScreen/PodcastsScreen'
import Magazines from 'components/Magazines/Magazines'
import EarlyReadingScreen from 'screens/control-panel/EarlyReadingScreen/EarlyReadingScreen'
import VideoGrammarScreen from 'screens/control-panel/VideoGrammarScreen/VideoGrammarScreen'
import WritingParticipateScreen from 'screens/control-panel/WrintingParticipateScreen/WritingParticipateScreen'
import TermsConditionsScreen from 'screens/control-panel/TermsConditionsScreen/TermsConditionsScreen'
import role from 'config/roles/roles'
import InboxManagerScreen from 'screens/control-panel/InboxManagerScreen/InboxManagerScreen'
import ClassBlogScreen from 'screens/control-panel/ClassBlogScreen/ClassBlogScreen'

var dashRoutes = [
  {
    path: '/user-page',
    name: 'My Information',
    icon: AccountBox,
    component: UserProfileScreen,
    layout: '/admin',
    role: role.ALL_USER_ROLES,
  },
  {
    path: '/inbox',
    name: 'Inbox',
    icon: AccountBox,
    component: InboxManagerScreen,
    layout: '/admin',
    role: role.ALL_USER_ROLES,
  },
  {
    path: '/classblog',
    name: 'ClassBlog',
    icon: AccountBox,
    component: ClassBlogScreen,
    layout: '/admin',
    role: role.ALL_USER_ROLES,
  },
  {
    collapse: true,
    name: 'Visitas',
    icon: 'content_paste',
    state: 'pageCollapse',
    role: role.EDITOR_ROLE,
    views: [
      {
        path: '/podcasts',
        name: 'Podcasts',
        icon: AccountBox,
        component: PodcastsScreen,
        layout: '/admin',
        role: role.ALL_USER_ROLES,
      },
      {
        path: '/magazines',
        name: 'Magazines',
        icon: AccountBox,
        component: Magazines,
        layout: '/admin',
        role: role.ALL_USER_ROLES,
      },
      {
        path: '/earlyreading',
        name: 'Early Reading',
        icon: AccountBox,
        component: EarlyReadingScreen,
        layout: '/admin',
        role: role.ALL_USER_ROLES,
      },
      {
        path: '/videogrammar',
        name: 'VideoGrammar',
        icon: AccountBox,
        component: VideoGrammarScreen,
        layout: '/admin',
        role: role.ALL_USER_ROLES,
      },
    ],
  },
  {
    collapse: true,
    name: 'Writing',
    icon: 'content_paste',
    state: 'pageCollapse',
    role: role.ALL_USER_ROLES,
    views: [
      {
        path: '/writing/participate',
        name: 'Participate',
        icon: Create,
        component: WritingParticipateScreen,
        layout: '/admin',
        role: role.ALL_USER_ROLES,
      },
      {
        path: '/terms-conditions',
        name: 'Participate',
        icon: Create,
        component: TermsConditionsScreen,
        layout: '/admin',
        role: role.ALL_USER_ROLES,
      },
    ],
  },
]

export default dashRoutes
