import { useDispatch } from 'react-redux'
import axios from 'axios'
import { Card, Fab, Grid, makeStyles, Paper } from '@material-ui/core'
import { ThumbUp } from '@material-ui/icons'
import { classBlogUpdate } from 'redux/actions/classBlogActions'
import styles from '../styles/classBlogItemStyles'

const useStyles = makeStyles(styles)

const ClassBlogItem = ({ classBlog }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleSubmitVote = () => {
    dispatch(classBlogUpdate(classBlog))
  }

  return (
    <div className={classes.root}>
      {classBlog && (
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <Card className={classes.cardRoot}>
              <Grid container spacing={3} className={classes.gridCard}>
                <Grid item md={12} sm={12} xs={12}>
                  <Paper className={classes.mainTitle} elevation={3}>
                    {classBlog.title}
                  </Paper>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <video
                    poster={`${axios.defaults.baseURL}/${classBlog.image}`}
                    className={classes.contentVideo}
                    controls
                  >
                    <source src={`${axios.defaults.baseURL}/${classBlog.video}`} type='audio/mp4' />
                    Este es un elemento de audio no soportado por tu navegador, prueba con otro
                  </video>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Fab
                    onClick={() => handleSubmitVote()}
                    variant='extended'
                    color='primary'
                    className={classes.actionLike}
                  >
                    <span>Vote</span>
                    <ThumbUp />
                    <span>{classBlog.vote.length}</span>
                  </Fab>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default ClassBlogItem
