import {
  CATEGORY_BY_TYPE_RESET,
  CATEGORY_BY_TYPE_SUCCESS,
  CATEGORY_BY_TYPE_FAIL,
  CATEGORY_BY_TYPE_REQUEST,
} from 'redux/constants/categoryConstants'

export const categoryByTypeReducer = (state = [], action) => {
  switch (action.type) {
    case CATEGORY_BY_TYPE_REQUEST:
      return { loadingCategoryByType: true }
    case CATEGORY_BY_TYPE_SUCCESS:
      return { loadingCategoryByType: false, categories: action.payload }
    case CATEGORY_BY_TYPE_FAIL:
      return { loadingCategoryByType: false, errorCategoryByType: action.payload }
    case CATEGORY_BY_TYPE_RESET:
      return {}
    default:
      return state
  }
}
