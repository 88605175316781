import {
  VIDEO_GRAMMAR_LIST_REQUEST,
  VIDEO_GRAMMAR_LIST_SUCCESS,
  VIDEO_GRAMMAR_LIST_FAIL,
  VIDEO_GRAMMAR_LIST_RESET,
} from 'redux/constants/videoGrammarConstants'

export const videoGrammarListReducer = (state = { videogrammars: [] }, action) => {
  switch (action.type) {
    case VIDEO_GRAMMAR_LIST_REQUEST:
      return { loadingVideoGrammarList: true }
    case VIDEO_GRAMMAR_LIST_SUCCESS:
      return {
        loadingVideoGrammarList: false,
        successVideoGrammarList: true,
        thatsIt: action.payload.length === 0 ? true : false,
        videogrammars: action.payload,
      }
    case VIDEO_GRAMMAR_LIST_FAIL:
      return { loadingVideoGrammarList: false, errorVideoGrammarList: action.payload }
    case VIDEO_GRAMMAR_LIST_RESET:
      return {}
    default:
      return state
  }
}
