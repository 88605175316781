const ButtonStyle = {
  confirmBtnCssClass: {
    backgroundColor: '#ebe6e2',
    color: '#333333 !important',
    padding: '10px',
    width: '5rem',
    borderRadius: '3px',
    '&:hover': {
      color: '#333333',
    },
  },
}

export default ButtonStyle
