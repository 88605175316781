const ADMIN_ROLE = 'admin'
const EDITOR_ROLE = 'editor'
const SUPER_ROLE = 'superadministrator'

const ALL_USER_ROLES = [ADMIN_ROLE, EDITOR_ROLE, SUPER_ROLE]

export default {
  ADMIN_ROLE,
  ALL_USER_ROLES,
  EDITOR_ROLE,
  SUPER_ROLE,
}
