const styles = {
  root: {},
  cardRoot: {
    maxWidth: '90%',
    margin: 'auto',
    padding: '0px 0px 40px 0px',
  },
  gridCard: {
    width: '100%',
    textAlign: 'center',
  },
  titleGrid: {
    color: '#ff6600',
  },
  buttonGrid: {
    height: '480px',
    borderRadius: '15px',
    '& img': {
      maxWidth: '100%',
      height: '480px',
      borderRadius: '15px',
    },
  },
  '@media screen and (max-width: 580px)': {
    cardRoot: {
      maxWidth: '100%',
    },
  },
}

export default styles
