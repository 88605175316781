const styles = {
  cardRoot: {
    width: '80%',
    margin: 'auto',
    '& h4': {
      fontSize: '32px',
      color: '#000d51',
      margin: '35px 0px 25px 0px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  gridCard: {
    backgroundColor: '#f5f5f5',
    padding: '10px',
  },
  gridInbox: {
    padding: '10px 30px',
  },
  cardInbox: {
    width: '100%',
    marginBottom: 10,
    textAlign: 'start',
  },
  cardHeaderRoot: {
    padding: 10,
    '& .MuiCardHeader-avatar': {
      marginRight: 10,
    },
    '& .MuiCardHeader-content': {
      overflow: 'hidden',
    },
    '& .MuiCardHeader-action': {
      margin: 0,
    },
  },
  btnwrapper: {
    padding: 10,
    '& svg': {
      margin: 0,
    },
  },
  imageDropdownButton: {
    padding: '0px',
    borderRadius: '50%',
    marginLeft: '25px',
    backgroundColor: '#f3f3f3',
  },
  navLink: {
    color: '#757575 !important',
    borderRadius: '33px',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: 0,
    display: 'inline-flex',
    '& svg': {
      width: 22,
      height: 22,
    },
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'rgba(200, 200, 200, 0.2)',
    },
  },
  btnSection: {
    marginLeft: '10px',
  },
  '@media screen and (max-width: 580px)': {
    cardRoot: {
      width: '100%',
    },
    btnSection: {
      width: '100%',
      marginLeft: '0px',
    },
  },
}

export default styles
