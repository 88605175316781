import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Card, CardActionArea, CardContent, CardMedia, Chip, Grid, makeStyles, Typography } from '@material-ui/core'
import { Stack } from '@mui/material'
import Loader from 'components/Loader/Loader'
import { useQueryParams } from 'shared/customHooks/useQueryParams'
import { getMagazineById } from 'redux/actions/magazineActions'
import ArticleByMagazine from './ArticleByMagazine'

import styles from '../styles/magazineLevelStyle'

const useStyles = makeStyles(styles)

const MagazineLevel = ({ history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const queries = useQueryParams()

  const [article, setArticle] = useState(null)
  const [articlesScholSubject, setArticlesScholSubject] = useState([])
  const [articlesEntertaiment, setArticlesEntertaiment] = useState([])

  const { loadingMagazineDetails, magazine } = useSelector((state) => state.magazineDetails)

  useEffect(() => {
    if (queries.id) {
      dispatch(getMagazineById(queries.id.toString()))
    }
  }, [dispatch, queries.id])

  useEffect(() => {
    if (magazine) {
      const article = magazine.articles.find((article) => article._id === queries.article)
      setArticle(article)
    }
  }, [magazine, queries])

  useEffect(() => {
    if (magazine) {
      const articlesSubject = magazine.articles.filter((article) => {
        if (article.section === 'school-subject' && Number(article.level) === Number(queries.level)) {
          return article
        }
      })
      if (articlesSubject) {
        setArticlesScholSubject(articlesSubject)
      } else {
        setArticlesScholSubject([])
      }
      const articlesEntertainment = magazine.articles.filter((article) => {
        if (article.section === 'entertainment' && Number(article.level) === Number(queries.level)) {
          return article
        }
      })
      if (articlesEntertainment) {
        setArticlesEntertaiment(articlesEntertainment)
      } else {
        setArticlesEntertaiment([])
      }
    }
  }, [magazine, queries])

  return (
    <>
      {loadingMagazineDetails ? (
        <Loader />
      ) : queries.article && article ? (
        <ArticleByMagazine article={article} />
      ) : (
        <div className={classes.root}>
          {
            <Card className={classes.cardRoot}>
              <Grid container className={classes.gridCard}>
                <Grid item md={12}>
                  <img
                    className={classes.mainImage}
                    src={`/assets/img/magazines/cabeceras/cabeceraIndexL${queries.level}.jpg`}
                  />
                </Grid>
                <Grid item md={12}>
                  <h2>School Subject</h2>
                </Grid>
                <Grid container spacing={3} className={classes.sectionArticles}>
                  {articlesScholSubject && articlesScholSubject.length > 0 ? (
                    articlesScholSubject.map((article) => (
                      <Grid item md={4} sm={6} xs={12} key={article._id}>
                        <Card
                          className={classes.articleCard}
                          onClick={() =>
                            history.push(
                              `/admin/magazines?id=${magazine._id}&level=${article.level}&article=${article._id}`
                            )
                          }
                        >
                          <CardActionArea>
                            <CardMedia
                              component='img'
                              height='200'
                              image={`${axios.defaults.baseURL}/${article.image}`}
                              alt='the-magazine'
                            />
                          </CardActionArea>
                          <CardContent>
                            <Typography className={classes.contentTitle} gutterBottom variant='h5' component='div'>
                              {article.title}
                            </Typography>
                            <hr />
                            <Stack className={classes.contentCategory} direction='row'>
                              <Chip className={classes.category} label='Literature' color='primary' />
                            </Stack>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <h5 className={classes.titleNotArticle}>Not Articles School Subject</h5>
                  )}
                </Grid>
                <Grid item md={12}>
                  <h2>Entertaiment</h2>
                </Grid>
                <Grid container spacing={3} className={classes.sectionArticles}>
                  {articlesEntertaiment && articlesEntertaiment.length > 0 ? (
                    articlesEntertaiment.map((article) => (
                      <Grid item md={4} sm={6} xs={12} key={article._id}>
                        <Card
                          className={classes.articleCard}
                          onClick={() =>
                            history.push(
                              `/admin/magazines?id=${magazine._id}&level=${article.level}&article=${article._id}`
                            )
                          }
                        >
                          <CardActionArea>
                            <CardMedia
                              component='img'
                              height='200'
                              image={`${axios.defaults.baseURL}/${article.image}`}
                              alt='the-magazine'
                            />
                          </CardActionArea>
                          <CardContent>
                            <Typography className={classes.contentTitle} gutterBottom variant='h5' component='div'>
                              {article.title}
                            </Typography>
                            <hr />
                            <Stack className={classes.contentCategory} direction='row'>
                              <Chip className={classes.category} label='Literature' color='primary' />
                              <Chip className={classes.category} label='February 2022' color='secondary' />
                              <Chip className={classes.category} label='Free Sample' color='primary' />
                            </Stack>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <h5 className={classes.titleNotArticle}>Not Articles Entertaiment</h5>
                  )}
                </Grid>
              </Grid>
            </Card>
          }
        </div>
      )}
    </>
  )
}

export default MagazineLevel
