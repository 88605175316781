import axios from 'axios'
import {
  EARLY_READING_LIST_REQUEST,
  EARLY_READING_LIST_SUCCESS,
  EARLY_READING_LIST_FAIL,
} from 'redux/constants/earlyReadingConstants'

export const getEarlyReading = () => async (dispatch) => {
  try {
    dispatch({ type: EARLY_READING_LIST_REQUEST })

    const { data } = await axios.get(`/api/earlyreadings`)

    dispatch({ type: EARLY_READING_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: EARLY_READING_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
