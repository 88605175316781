const styles = {
  cardRoot: {
    width: '90%',
    margin: 'auto',
    padding: '20px 0px 40px 0px',
    '& h4': {
      fontSize: '32px',
      color: '#000d51',
      margin: '35px 0px 25px 0px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  gridCard: {
    padding: '0px 20px',
    textAlign: 'center',
  },
  titlesSection: {
    textAlign: 'center',
    '& h3': {
      fontWeight: 'bold',
      fontSize: '32px',
      color: '#000d51',
    },
    '& p': {
      fontSize: '18px',
      margin: '20px 0px',
    },
  },
  mainImage: {
    width: '100%',
  },
  sectionArticles: {
    margin: 'auto',
  },
  articleCard: {
    cursor: 'pointer',
    textAlign: 'start',
    color: '#f60',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  contentTitle: {
    textAlign: 'start',
  },
  contentCategory: {
    justifyContent: 'start',
    flexWrap: 'wrap',
  },
  category: {
    margin: '5px',
  },
  titleNotArticle: {
    textAlign: 'center',
    width: '100%',
  },
  contentTitle: {
    margin: '30px auto',
    color: '#f60',
    border: '3px solid #f60',
    '& h2': {
      margin: '5px 0px 0px 0px',
    },
  },
  '@media screen and (max-width: 580px)': {
    cardRoot: {
      width: '100%',
    },
  },
}

export default styles
