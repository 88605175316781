import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { logout } from 'redux/actions/userActions'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'
import Hidden from '@material-ui/core/Hidden'
import Popper from '@material-ui/core/Popper'
import Divider from '@material-ui/core/Divider'
import Person from '@material-ui/icons/Person'
import Button from 'components/CustomButtons/Button'
import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle'

const useStyles = makeStyles(styles)

const HeaderLinks = ({ rtlActive }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [openNotification, setOpenNotification] = useState(null)
  const [openProfile, setOpenProfile] = useState(null)

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null)
    } else {
      setOpenNotification(event.currentTarget)
    }
  }
  const handleCloseNotification = () => {
    setOpenNotification(null)
  }
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null)
    } else {
      setOpenProfile(event.currentTarget)
    }
  }
  const handleCloseProfile = () => {
    setOpenProfile(null)
  }

  const searchButton =
    classes.top +
    ' ' +
    classes.searchButton +
    ' ' +
    classNames({
      [classes.searchRTL]: rtlActive,
    })
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  })
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  })
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  })

  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        <Button
          color='transparent'
          aria-label='Person'
          justIcon
          aria-owns={openProfile ? 'profile-menu-list' : null}
          aria-haspopup='true'
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
        >
          <Person
            className={
              classes.headerLinksSvg + ' ' + (rtlActive ? classes.links + ' ' + classes.linksRTL : classes.links)
            }
          />
          <Hidden mdUp implementation='css'>
            <span onClick={handleClickProfile} className={classes.linkText}>
              Perfil
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement='bottom'
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} id='profile-menu-list' style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role='menu'>
                    <NavLink to='/admin/user-page' className={classes.itemLink}>
                      <MenuItem onClick={() => handleCloseProfile(null)} className={dropdownItem}>
                        Perfil
                      </MenuItem>
                    </NavLink>
                    <Divider light />
                    <MenuItem onClick={() => dispatch(logout())} className={dropdownItem}>
                      Salir
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  )
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
}

export default HeaderLinks
