import { Button, Grid, makeStyles } from '@material-ui/core'
import styles from '../styles/magazineArticleStyle'

const useStyles = makeStyles(styles)

const data = [
  {
    level: 1,
    title: '1. A1/Starters',
    text:
      'Present simple and continuous (without reference to the future), CAN for ability and requests, simple basic vocabulary for everyday situations and items, possessive adjectives.',
  },
  {
    level: 2,
    title: '2. A1/Movers',
    text:
      'Past simple regular and irregular verbs, MUST and HAVE GOT TO, comparatives and superlatives, more illustrative and expressive vocabulary for descriptions, question words, relative clauses, prepositions of time.',
  },
  {
    level: 3,
    title: '3. A2/Flyers',
    text:
      'Future tense, Present and past perfect, past continuous, modal verbs MIGHT, MAY, SHOULD, SHALL, adverbs, tag questions, IF clauses (but with no conditionals), conjunctions, slightly more complicated and more powerful verbs and adjectives.',
  },
  {
    level: 4,
    title: '4. A2-B1/Ket-Pet',
    text:
      'All verb tenses, including 1st conditional, HOW questions, all MODALS, Countables and Uncountables, Compound Words, Multi-word verbs, some Idioms.',
  },
  {
    level: 5,
    title: '5. B1/Pet',
    text:
      'Narrative past tense, Broader range of intensifiers; So, such, too, enough, 2nd and 3rd Conditionals, Reported Speech, Phrasal verbs extended, Simple Passive, Full range of quotidian vocabulary.',
  },
  {
    level: 6,
    title: '6. B1-B2/Pet-Fce',
    text:
      'Embedded clauses, mixed conditionals, complex passives, challenging vocabulary that includes literary and technical terms.',
  },
  {
    level: 7,
    title: '7. B2-CI/Fce-Cae',
    text: 'Full range of grammar structures and vocabulary for fluent students.',
  },
]

const MagazineArticle = ({ magazine, history, setOpen }) => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item md={12}>
        <div className={classes.rootSection}>
          <h5>Read The Magazine by choosing your level. *All of our levels are adapted to the CEFR.</h5>
        </div>
      </Grid>
      {data.map(({ level, title, text }, index) => (
        <Grid item md={12} key={index}>
          <Button
            onClick={() => [history.push(`/admin/magazines?id=${magazine._id}&level=${level}`), setOpen(false)]}
            className={classes.btnItem}
          >
            <div className={classes.rootItemImage}>
              <img src={`/assets/img/magazines/levels/${level}c.png`} />
            </div>
            <div className={classes.rootItemInfo}>
              <p>{title}</p>
              <p>{text}</p>
            </div>
          </Button>
        </Grid>
      ))}
    </Grid>
  )
}

export default MagazineArticle
