import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useQueryParams = () => {
  const [queries, setQueries] = useState({})
  const { search } = useLocation()

  const onDecodeParams = useCallback((params) => {
    const replaceFirstCharacter = params.replace('?', '')
    const splitString = replaceFirstCharacter.split('&')

    const formattedQueries = {}
    splitString.forEach((query) => {
      const [key, value] = query.split('=')
      Object.assign(formattedQueries, {
        [key]: value,
      })
    })
    setQueries(formattedQueries)
  }, [])

  useEffect(() => {
    if (search.trim().length > 0) {
      onDecodeParams(search)
    } else {
      setQueries({})
    }
  }, [onDecodeParams, search])
  return queries
}
