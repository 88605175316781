const styles = {
  cardRoot: {
    width: '80%',
    margin: 'auto',
    padding: '20px 0px 40px 0px',
    '& h4': {
      fontSize: '32px',
      color: '#000d51',
      margin: '35px 0px 25px 0px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  gridCard: {
    padding: '0px 30px',
    textAlign: 'center',
  },
  gridForm: {
    padding: '30px 50px',
    fontFamily: 'roboto',
  },
  mainTitle: {
    borderRadius: '25px',
    backgroundColor: '#009cf4',
    padding: '15px 0px',
    color: '#fff',
    fontSize: '22px',
  },
}

export default styles
