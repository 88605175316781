import { useHistory } from 'react-router-dom'
import { Grid, Link, Divider, makeStyles } from '@material-ui/core'
import Button from '../CustomButtons/Button'
import collapseLogo from '../../assets/img/collapseLogo.png'
import styles from './styles/wsFooterStyle'
const useStyles = makeStyles(styles)

const WSFooter = () => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.footerItem}>
          <Grid container>
            <Grid item xs={12} md={3} className={classes.logoDiv}>
              <img src={collapseLogo} alt='Logo Pacto Educativo Footer' className={classes.logoFooter} />
            </Grid>
            <Grid item xs={12} md={3} className={classes.logoDiv}>
              <img src={collapseLogo} alt='Logo Planet Fraternity Footer' className={classes.logoFooter} />
            </Grid>
            <Grid item xs={12} md={3} className={classes.logoDiv}>
              <img src={collapseLogo} alt='Logo The Improving Co. Footer' className={classes.logoFooter} />
            </Grid>
            <Grid item xs={12} md={3} className={classes.logoDiv}>
              <img src={collapseLogo} alt='Logo OIEC Footer' className={classes.logoFooter} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.footerItem}>
          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <span className={classes.listTitle}>The Magazine</span>
              <ul className={classes.footerLinksList}>
                <li>
                  <Button link className={classes.footerLink} onClick={() => history.push('/how-it-works')}>
                    hola
                  </Button>
                </li>
                {/* <li>
                                    <Button link className={classes.footerLink} onClick={() => history.push('/the-magazine')}>
                                        The Magazine
                                    </Button>
                                </li> */}
                <li>
                  <Button link className={classes.footerLink} onClick={() => history.push('/i-can')}>
                    hola
                  </Button>
                </li>
                <li>
                  <Button link className={classes.footerLink} onClick={() => history.push('/pastoral')}>
                    hola
                  </Button>
                </li>
                <li>
                  <Button link className={classes.footerLink} onClick={() => history.push('/contact')}>
                    hola
                  </Button>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <span className={classes.listTitle}>Our Methodology</span>
              <ul className={classes.footerLinksList}>
                <li>
                  <Button link className={classes.footerLink} onClick={() => history.push('/feel')}>
                    hola
                  </Button>
                </li>
                <li>
                  <Button link className={classes.footerLink} onClick={() => history.push('/imagine')}>
                    hola
                  </Button>
                </li>
                <li>
                  <Button link className={classes.footerLink} onClick={() => history.push('/act')}>
                    hola
                  </Button>
                </li>
                <li>
                  <Button link className={classes.footerLink} onClick={() => history.push('/share')}>
                    hola
                  </Button>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <span className={classes.listTitle}>Platform</span>
              <ul className={classes.footerLinksList}>
                {/* <li>
                                    <Button link className={classes.footerLink} onClick={() => history.push('/login')}>
                                        {t('layout.t_login')}
                                    </Button>
                                </li>
                                <li>
                                    <Button link className={classes.footerLink} onClick={() => history.push('/forgot-password')}>
                                        {t('layout.t_recover_pass')}
                                    </Button>
                                </li> */}
                <li>
                  <Button link className={classes.footerLink} onClick={() => history.push('/center-register')}>
                    hola
                  </Button>
                </li>
                <li>
                  <Button link className={classes.footerLink} onClick={() => history.push('/terms-conditions')}>
                    hola
                  </Button>
                </li>
                <li>
                  <Button link className={classes.footerLink} onClick={() => history.push('/cookies-policy')}>
                    hola
                  </Button>
                </li>
                {/* <li>
                                    <Button link className={classes.footerLink} onClick={() => history.push('/support')}>
                                        {t('layout.t_support')}
                                    </Button>
                                </li> */}
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <span className={classes.listTitle}>Social Networks</span>
              <ul className={classes.footerLinksList}>
                <li>
                  <Button link className={classes.footerLink}>
                    <Link
                      href={`https://www.facebook.com/planetfraternity/`}
                      target='_blank'
                      rel='noopener noreferrer'
                      underline='none'
                    >
                      Facebook
                    </Link>
                  </Button>
                </li>
                <li>
                  <Button link className={classes.footerLink}>
                    <Link
                      href={`https://www.instagram.com/planetfraternity/`}
                      target='_blank'
                      rel='noopener noreferrer'
                      underline='none'
                    >
                      Instagram
                    </Link>
                  </Button>
                </li>
                <li>
                  <Button link className={classes.footerLink}>
                    <Link
                      href={`https://www.linkedin.com/company/planet-fraternity/`}
                      target='_blank'
                      rel='noopener noreferrer'
                      underline='none'
                    >
                      LinkedIn
                    </Link>
                  </Button>
                </li>
                {/* <li>
                    <Button link className={classes.footerLink} onClick={() => history.push('/support')}>
                        {t('layout.t_support')}
                    </Button>
                </li> */}
              </ul>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.footerItem}>
          contacto@themagazine.com 🌍 www.themagazine.com
        </Grid>
        <Grid item xs={12} className={classes.footerItem}>
          Copyright &copy; | The Magazine | 2022
        </Grid>
      </Grid>
    </div>
  )
}

export default WSFooter
