const styles = {
  gridCard: {
    textAlign: 'center',
  },
  mainTitle: {
    color: '#fff',
    backgroundColor: '#000d51',
    padding: '20px 0px',
  },
  levelSection: {
    width: '100%',
    padding: '20px 0px',
    textTransform: 'initial',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f60',
      color: '#fff',
    },
  },
}

export default styles
