const styles = {
  loaderWrapper: {
    width: '25px',
    height: '25px',
    margin: 'auto',
    textAlign: 'center',
  },
  loader: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    border: '3px solid #162534',
    borderTopColor: '#009cf4',
    borderBottomColor: '#ff6600',
    borderRadius: '50%',
    animation: '$rotate 5s linear infinite',
  },
  loaderInner: {
    border: '3px solid #162534',
    borderLeftColor: '#d9d9d9',
    borderRightColor: '#d9d9d9',
    borderTopColor: '#36f372',
    borderBottomColor: '#d9d9d9',
    animationDuration: '2.5s',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'scale(1) rotate(360deg)',
    },
    '50%': {
      transform: 'scale(1) rotate(-360deg)',
    },
    '100%': {
      transform: 'scale(1) rotate(360deg)',
    },
  },
}

export default styles
