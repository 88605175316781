import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Checkbox, FormControlLabel, Grid, Link, makeStyles, Paper, TextField } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import Loader from 'components/Loader/Loader'
import { registerWritingParticipation } from 'redux/actions/writingParticipationActions'
import { WRITING_PARTICIPATION_REGISTER_RESET } from 'redux/constants/writingParticipationConstants'
import styles from './styles/writingParticipateScreenStyles'

const useStyles = makeStyles(styles)

const WritingParticipateScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [isAccept, setIsAccept] = useState(false)
  const [participationText, setParticipationText] = useState('')
  const [successRegister, setSuccessRegister] = useState(false)

  const { userInfo } = useSelector((state) => state.userLogin)
  const { loadingWritingParticipationRegister, successWritingParticipationRegister } = useSelector(
    (state) => state.writingParticipationRegister
  )

  useEffect(() => {
    if (successWritingParticipationRegister) {
      setSuccessRegister(true)
      setIsAccept(false)
      setTimeout(() => {
        setSuccessRegister(false)
        setParticipationText('')
        dispatch({ type: WRITING_PARTICIPATION_REGISTER_RESET })
      }, 2000)
    }
  }, [successWritingParticipationRegister])

  const handleToggle = (e) => {
    setIsAccept(e.target.checked)
  }

  const handleWritingSubmit = (e) => {
    console.log('hola')
    e.preventDefault()
    const participation = {
      user: userInfo,
      participationText,
    }
    dispatch(registerWritingParticipation(participation))
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Card className={classes.cardRoot}>
            <Grid container className={classes.gridCard}>
              <Grid item md={12} sm={12} xs={12}>
                <Paper className={classes.mainTitle} elevation={3}>
                  <div>Participate in the competition</div>
                </Paper>
              </Grid>
              <Grid item md={12}>
                <h2>What is the tradition in your city during the Easter holidays?</h2>
              </Grid>
            </Grid>
            <form onSubmit={handleWritingSubmit}>
              <Grid container spacing={1} className={classes.gridForm}>
                <Grid item md={12} sm={12} xs={12}>
                  <label className={classes.areaLabel}>
                    What is the tradition in your city during the Easter holidays?
                  </label>
                  <TextField
                    className={classes.areaField}
                    id='outlined-textarea'
                    placeholder='Write your participation...'
                    multiline
                    value={participationText}
                    onChange={(e) => setParticipationText(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={(e) => handleToggle(e)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                        inputProps={{
                          required: true,
                        }}
                      />
                    }
                    classes={{ label: classes.label, root: classes.labelRoot }}
                  />
                  <span>
                    <Button link className={classes.btnLink}>
                      <Link href={`/admin/terms-conditions`} target='_blank' rel='noopener noreferrer' underline='none'>
                        <span>I have read the conditions of the competitions*</span>
                      </Link>
                    </Button>
                  </span>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Button type='submit' color={successRegister ? 'success' : 'primary'} disabled={!isAccept} block>
                    {loadingWritingParticipationRegister ? (
                      <Loader size={'sm'} />
                    ) : successRegister ? (
                      'Register Successfully'
                    ) : (
                      'Submit Participation'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default WritingParticipateScreen
