const styles = {
  root: {
    margin: '130px auto',
    width: '70%',
  },
  cardRoot: {
    width: '100%',
    margin: 'auto',
    padding: '20px 0px 40px 0px',
    '& h4': {
      fontSize: '32px',
      color: '#000d51',
      margin: '35px 0px 25px 0px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  gridCard: {
    padding: '0px 40px',
    textAlign: 'center',
  },
  mainTitle: {
    borderRadius: '25px',
    backgroundColor: '#009cf4',
    padding: '15px 0px',
    color: '#fff',
    fontSize: '22px',
  },
  authorSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& img ': {
      height: 66,
      width: 66,
      borderRadius: '50px',
    },
    '& h5': {
      marginLeft: '15px',
      fontSize: '18px',
    },
  },
  cardImg: {
    maxWidth: '100%',
  },
  cardText: {
    fontFamily: 'roboto',
    padding: '20px 0px',
    textAlign: 'start',
    fontWeight: '400',
  },
  '@media screen and (max-width: 780px)': {
    root: {
      width: '90%',
    },
  },
}

export default styles
