import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Card, CardActionArea, CardContent, CardMedia, Grid, makeStyles, Paper } from '@material-ui/core'
import Loader from 'components/Loader/Loader'
import ClassBlogItem from './components/ClassBlogItem'
import { useQueryParams } from 'shared/customHooks/useQueryParams'
import { getClassBlogs } from 'redux/actions/classBlogActions'
import { CLASSBLOG_UPDATE_RESET } from 'redux/constants/classBlogConstants'
import styles from './styles/classBlogScreenStyles'

const useStyles = makeStyles(styles)

const ClassBlogScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const queries = useQueryParams()

  const [classBlog, setClassBlog] = useState(null)
  const [classBlogOne, setClassBlogOne] = useState(null)
  const [newClassBlogs, setNewClassBlogs] = useState([])

  const { loadingClassBlogList, classblogs } = useSelector((state) => state.classBlogList)
  const { successClassBlogUpdate, classblogUpdated } = useSelector((state) => state.classBlogUpdate)

  useEffect(() => {
    dispatch(getClassBlogs())
  }, [dispatch])

  useEffect(() => {
    if (successClassBlogUpdate) {
      dispatch(getClassBlogs())
      setClassBlog(classblogUpdated)
      dispatch({ type: CLASSBLOG_UPDATE_RESET })
    }
  }, [classblogUpdated, successClassBlogUpdate])

  useEffect(() => {
    if (classblogs) {
      const classblog = classblogs.find((classblog) => classblog.title === 'Hogar del Buen Consejo')
      setClassBlogOne(classblog)
      const newClassBlogs = classblogs.filter((classblog) => classblog.title !== 'Hogar del Buen Consejo')
      setNewClassBlogs(newClassBlogs)
    }
  }, [classblogs])

  useEffect(() => {
    if (classblogs) {
      const classblog = classblogs.find((classblog) => classblog._id === queries.class)
      console.log(classblog)
      if (classblog) {
        setClassBlog(classblog)
      } else {
        setClassBlog(null)
      }
    }
  }, [classblogs, queries])

  return (
    <>
      {queries.class && classBlog ? (
        <ClassBlogItem classBlog={classBlog} />
      ) : loadingClassBlogList ? (
        <Loader />
      ) : (
        <div className={classes.root}>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <Card className={classes.cardRoot}>
                <Grid container className={classes.gridCard}>
                  <Grid item md={12}>
                    <div className={classes.titlesSection}>
                      <h3>Welcome to The Magazine ClassBlog</h3>
                      <p>
                        Do you want to participate in our ClassBlog? Talk about it with your teacher and classmates. The
                        Magazine will go to your school and record your special activities.
                      </p>
                      <p>Do you have any idea what your class could do?</p>
                      <p>Now you can vote for the best videos.</p>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.gridCard}>
                  {classBlogOne && (
                    <Grid item md={12}>
                      <Paper className={classes.mainTitle} elevation={3}>
                        {classBlogOne.title}
                      </Paper>
                      <img
                        onClick={() => history.push(`/admin/classblog?class=${classBlogOne._id}`)}
                        className={classes.imgGrammarOne}
                        src={`${axios.defaults.baseURL}/${classBlogOne.image}`}
                        alt={classBlogOne._id}
                      />
                    </Grid>
                  )}
                  {newClassBlogs && newClassBlogs ? (
                    newClassBlogs.map((classblog) => (
                      <Grid key={classblog._id} item md={4} sm={6} xs={12}>
                        <Card
                          className={classes.articleCard}
                          onClick={() => history.push(`/admin/classblog?class=${classblog._id}`)}
                        >
                          <CardActionArea>
                            <CardMedia
                              component='img'
                              width='200'
                              image={`${axios.defaults.baseURL}/${classblog.image}`}
                              alt={classblog._id}
                            />
                          </CardActionArea>
                          <CardContent className={classes.cardContent}>
                            <h5>{classblog.title}</h5>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <h2>ClassBlog Not Found. Come back soon!</h2>
                  )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default ClassBlogScreen
