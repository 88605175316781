const styles = {
  cardRoot: {
    maxWidth: '80%',
    margin: 'auto',
    padding: '20px',
  },
  gridCard: {
    width: '100%',
    margin: 'auto',
    textAlign: 'center',
  },
  mainContent: {
    width: '100%',
    margin: 'auto',
    padding: '10px 0px',
    '& p': {
      margin: 0,
    },
  },
  contentArticle: {
    margin: 'auto',
    cursor: 'pointer',
    '& a': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& span': {
      color: '#f60',
      marginTop: '6px',
    },
    '& svg': {
      color: '#f60',
    },
  },
  imageArticle: {
    position: 'relative',
    '& img': {
      width: '100%',
    },
    '& h2': {
      margin: 0,
      textAlign: 'start',
      fontSize: '46px',
      position: 'absolute',
      top: '80%',
      left: '3%',
      WebkitTextFillColor: '#1f1073',
      WebkitTextStrokeColor: '#99a9ff',
      WebkitTextStrokeWidth: '2px',
    },
  },
  textArticle: {
    fontFamily: 'sans-serif',
    fontSize: '32px',
    lineHeight: '40px',
    padding: '30px 0px',
  },
  audioArticle: {
    '& audio': {
      width: '100%',
      boxShadow:
        '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
      borderRadius: '30px',
    },
  },
  articleAuthor: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '40px 0px',
    width: '100%',
    flexWrap: 'wrap',
  },
  articleCategory: {
    padding: '20px 35px',
    fontSize: '18px',
  },
  authorSection: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'start',
    '& img ': {
      height: 66,
      width: 66,
      borderRadius: '50px',
    },
    '& h5': {
      marginLeft: '15px',
      fontSize: '18px',
    },
  },
  barCard: {
    height: '15px',
    width: '100%',
    background: '#ffcd0d',
  },
  '@media screen and (max-width: 980px)': {
    cardRoot: {
      maxWidth: '90%',
    },
    authorSection: {
      marginTop: '20px',
    },
  },
  '@media screen and (max-width: 580px)': {
    cardRoot: {
      maxWidth: '100%',
    },
    imageArticle: {
      position: 'initial',
      '& h2': {
        paddingTop: '20px',
        position: 'initial',
        textAlign: 'center',
      },
    },
  },
}

export default styles
