import { useEffect, useState } from 'react'
import ReactTable from 'components/ReactTable/ReactTable'
import { format } from 'date-fns'
import { Card, Grid, makeStyles } from '@material-ui/core'
import { Delete, Visibility } from '@material-ui/icons'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import Button from '../../../components/CustomButtons/Button'
import { orderByString } from 'shared/functionOrder/functionsOrder'
import styles from './styles/inboxManagerScreenStyles'

const useStyles = makeStyles(styles)

const emailData = [
  {
    subject: 'Aviso Importante para todos los estudiantes',
    from: 'Abraham Rivas',
    date: Date.now(),
  },
  {
    subject: 'Aviso Importante',
    from: 'Jose Perez',
    date: Date.now(),
  },
  {
    subject: 'Aviso Importante',
    from: 'Jose Perez',
    date: Date.now(),
  },
  {
    subject: 'Aviso Importante',
    from: 'Jose Perez',
    date: Date.now(),
  },
  {
    subject: 'Aviso Importante',
    from: 'Jose Perez',
    date: '01/01/2022',
  },
  {
    subject: 'Aviso Importante',
    from: 'Jose Perez',
    date: Date.now(),
  },
]

const InboxManagerScreen = () => {
  const classes = useStyles()

  const [data, setData] = useState([])

  useEffect(() => {
    const data = emailData.map((data) => {
      return {
        subject: data.subject,
        from: data.from,
        date: data.date,
        actions: (
          <div className='actions-right'>
            <Button justIcon round simple onClick={() => 'click'} color='success' className='edit'>
              <Visibility />
            </Button>
            <Button justIcon round simple onClick={() => 'click'} color='danger' className='delete'>
              <Delete />
            </Button>
          </div>
        ),
      }
    })
    setData(data)
  }, [emailData])

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Card className={classes.cardRoot}>
            <Grid container className={classes.gridCard}>
              <Grid item md={12} sm={12} xs={12}>
                <Button className={classes.btnSection} color='primary'>
                  System Message
                </Button>
                <Button color='info' className={classes.btnSection}>
                  Group Message
                </Button>
                <Button color='facebook' className={classes.btnSection}>
                  Teacher Message
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.rootItem}>
                <Card>
                  <CardHeader color='primary' icon>
                    <h4 className={classes.cardIconTitle}>System Message</h4>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      columns={[
                        {
                          Header: 'Subject',
                          accessor: 'subject',
                          sortType: (paramA, paramB) => orderByString(paramA, paramB, 'subject'),
                          size: 'big',
                        },
                        {
                          Header: 'From',
                          accessor: 'from',
                          sortType: (paramA, paramB) => orderByString(paramA, paramB, 'from'),
                          size: 'big',
                        },
                        {
                          Header: 'Date',
                          accessor: 'date',
                          Cell: ({ cell: { value } }) => <>{format(new Date(value), 'dd/MM/yyyy')}</>,
                          size: 'big',
                        },
                        {
                          Header: 'Acciones',
                          accessor: 'actions',
                          size: 'medium',
                        },
                      ]}
                      data={data}
                    />
                  </CardBody>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default InboxManagerScreen
