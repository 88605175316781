import { Helmet } from 'react-helmet'

const Meta = () => {
  const time = new Date()
  return (
    <Helmet>
      <link href={`https://use.fontawesome.com/releases/v5.0.7/css/all.css?v=${time}`} rel='stylesheet' />
      <link
        rel='stylesheet'
        type='text/css'
        href={`https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons?v=${time}`}
      />
      <link href={`https://fonts.googleapis.com/icon?family=Material+Icons&?v=${time}`} rel='stylesheet' />
      <link
        href={`https://fonts.googleapis.com/css?family=Baloo+Bhaina|Material+Icons?v=${time}`}
        rel='stylesheet'
        type='text/css'
      />
    </Helmet>
  )
}

export default Meta
