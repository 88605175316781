import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle'

const styles = {
  ...customSelectStyle,
  formControlMargins: {
    margin: '3px 0 !important',
  },
  gridContainer: {
    justifyContent: 'center',
  },
  widthSmall: {
    minWidth: '100px !important',
  },
  widthMedium: {
    minWidth: '135px !important',
  },
  widthBig: {
    minWidth: '170px !important',
  },
  widthSmall: {
    minWidth: '100px !important',
  },
  widthMedium: {
    minWidth: '135px !important',
  },
  widthBig: {
    minWidth: '170px !important',
  },
}

export default styles
