import { Card, Grid, makeStyles, Paper } from '@material-ui/core'
import styles from './styles/termsConditionsScreenStyles'

const useStyles = makeStyles(styles)

const TermsConditionsScreen = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Card className={classes.cardRoot}>
            <Grid container className={classes.gridCard}>
              <Grid item md={12} sm={12} xs={12}>
                <Paper className={classes.mainTitle} elevation={3}>
                  <div>Bases del curso</div>
                </Paper>
              </Grid>
            </Grid>
            <Grid container className={classes.gridForm} spacing={1}>
              <Grid item md={12}>
                <p>
                  - El concurso está abierto a los alumnos de todos los niveles, desde primero de Primaria hasta segundo
                  de Bachillerato, que actualmente disfruten de nuestra revista.
                </p>
              </Grid>
              <Grid item md={12}>
                <p>
                  - Los alumnos tendrán que desarrollar un texto real o inventado sobre el tema a tratar y justificarlo.
                </p>
              </Grid>
              <Grid item md={12}>
                <p>- Los alumnos de 1º y 2º de Primaria tendrán un mínimo de 100 palabras.</p>
              </Grid>
              <Grid item md={12}>
                <p>- Los alumnos de 3º y 4º de Primaria tendrán un mínimo de 150 palabras.</p>
              </Grid>
              <Grid item md={12}>
                <p>- Los alumnos de 5º y 6º tendrán un mínimo de 200 palabras.</p>
              </Grid>
              <Grid item md={12}>
                <p>- Los alumnos de Secundaria tendrán un mínimo de 250 palabras.</p>
              </Grid>
              <Grid item md={12}>
                <p>- Los alumnos de Bachillerato tendrán un mínimo de 300 palabras.</p>
              </Grid>
              <Grid item md={12}>
                <p>- Los trabajos que se presenten tienen que ser originales e inéditos.</p>
              </Grid>
              <Grid item md={12}>
                <p>- Los trabajos deberán estar escritos en inglés.</p>
              </Grid>
              <Grid item md={12}>
                <p>
                  - Se valorará la originalidad de cada texto. El equipo de The Magazine apreciará la peculiaridad de
                  cada uno de los textos.
                </p>
              </Grid>
              <Grid item md={12}>
                <p>- Los alumnos, además, deberán rellenar el resto del formulario para poder participar.</p>
              </Grid>
              <Grid item md={12}>
                <p>- Se podrán contestar las preguntas hasta el último día del mes.</p>
              </Grid>
              <Grid item md={12}>
                <p>
                  - Todos los alumnos que participen y cumplan <strong>TODOS LOS REQUISITOS</strong> obtendrán 5
                  estrellas en la nueva pestaña de <strong>WRITING POINTS</strong>.
                </p>
              </Grid>
              <Grid item md={12}>
                <p>- Se tendrá en cuenta el nivel de cada alumno por curso.</p>
              </Grid>
              <Grid item md={12}>
                <p>- Durante la primera semana del siguiente mes se darán a conocer los nombres de los ganadores.</p>
              </Grid>
              <Grid item md={12}>
                <p>- Las respuestas vencedoras serán publicadas en nuestra página web y en nuestras redes sociales.</p>
              </Grid>
              <Grid item md={12}>
                <p>
                  - Los alumnos premiados tendrán la posibilidad de grabar un audio leyendo su texto, como hacen nuestro
                  equipo de redactores.
                </p>
              </Grid>
              <Grid item md={12}>
                <p>
                  - Es <strong>INDISPENSABLE</strong> estar registrados para poder enviar la participación. Así nos será
                  más fácil premiaros con las estrellas si cumplís los requisitos.
                </p>
              </Grid>
              <Grid item md={12}>
                <p>- Recuerda que ahora tus profesores recibirán tus Writing y podrás ver la corrección en clase.</p>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default TermsConditionsScreen
