import { container } from '../../../assets/jss/material-dashboard-pro-react'
const styles = {
  container,
  root: {
    padding: '40px 100px',
    borderTop: '5px solid #2e6da4',
    color: '#000d51',
    fontWeight: 'bold',
    backgroundColor: '#fff',
    zIndex: 1000,
  },
  footerItem: {
    padding: '0 0 50px',
    display: 'flex',
    justifyContent: 'center',
  },
  listTitle: {
    fontSize: '20px',
  },
  logoDiv: {
    textAlign: 'center',
    margin: '0 0 50px',
  },
  logoFooter: {
    height: '140px',
  },
  footerLinksList: {
    padding: 0,
    color: 'white',
  },
  footerLink: {
    color: '#000d51',
    fontWeight: 'bold',
    margin: '0 3px',
    '& a': {
      color: '#2e6da4',
    },
  },
  divider: {
    margin: '30px',
    backgroundColor: '#000d51',
  },

  '@media screen and (max-width: 590px)': {
    root: {
      padding: '15px',
    },
    footerLinksList: {
      display: 'flex',
      flexDirection: 'column',
      color: 'white',
    },
    logoFooter: {
      maxWidth: '100%',
      height: '100%',
    },
  },
}

export default styles
