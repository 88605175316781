const styles = {
  cardRoot: {
    width: '90%',
    margin: 'auto',
    padding: '20px 0px 40px 0px',
    '& h4': {
      fontSize: '32px',
      color: '#000d51',
      margin: '35px 0px 25px 0px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  gridCard: {
    padding: '0px 30px',
    textAlign: 'center',
  },
  contentSection: {
    margin: '10px',
  },
  articleCard: {
    width: '100%',
    cursor: 'pointer',
    textAlign: 'start',
    color: '#f60',
    // boxShadow:
    //   '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  titlesSection: {
    textAlign: 'center',
    '& h3': {
      fontWeight: 'bold',
      fontSize: '32px',
      color: '#000d51',
    },
    '& p': {
      fontSize: '18px',
      margin: '20px 0px',
    },
  },
  mainTitle: {
    borderRadius: '25px',
    backgroundColor: '#009cf4',
    padding: '15px 0px',
    color: '#fff',
    fontSize: '22px',
    margin: '20px 0px',
  },
  imgGrammarOne: {
    cursor: 'pointer',
    borderRadius: '10px',
    width: '100%',
  },
  cardContent: {
    paddingTop: '0px',
  },
  '@media screen and (max-width: 580px)': {
    cardRoot: {
      width: '100%',
    },
  },
}

export default styles
