import { AccountBox } from '@mui/icons-material'
import FaqsScreen from 'screens/website/FaqsScren/FaqsScreen'
import HomeScreen from 'screens/website/HomeScreen/HomeScreen'
import WritingWinnersScreen from 'screens/website/WritingWinnersScreen/WritingWinnersScreen'

var websiteRoutes = [
  {
    path: '/',
    name: 'Home',
    icon: AccountBox,
    component: HomeScreen,
    layout: '/',
  },
  {
    path: 'faqs',
    name: 'Home',
    icon: AccountBox,
    component: FaqsScreen,
    layout: '/',
  },
  {
    path: 'writing-winners',
    name: 'Writing Winners',
    icon: AccountBox,
    component: WritingWinnersScreen,
    layout: '/',
  },
]

export default websiteRoutes
