import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import SweetAlert from 'react-bootstrap-sweetalert'
import style from '../styles/changePasswordAlertStyles' 

const useStyles = makeStyles(style)

const ChangePasswordAlert = () => {
  const classes = useStyles()
  const [alert, setAlert] = useState(null)

  const { userInfo } = useSelector((state) => state.userLogin)

  useEffect(() => {
    if (userInfo.isDefaultPassword) {
      setAlert(
        <SweetAlert
          info
          style={{ display: 'block', marginTop: '-100px' }}
          title='Cambiar contraseña!'
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.confirmBtnCssClass}
        >
          Hola {`${userInfo.name} `} , por cuestiones de seguridad es necesario que cambie la contraseña asignada por
          defecto.
        </SweetAlert>
      )
    }
  }, [userInfo])

  return <div>{alert}</div>
}
export default ChangePasswordAlert