const styles = {
  root: {
    margin: '130px auto',
    width: '70%',
  },
  cardRoot: {
    width: '100%',
    margin: 'auto',
    padding: '20px 0px 40px 0px',
    '& h4': {
      fontSize: '32px',
      color: '#000d51',
      margin: '35px 0px 25px 0px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  gridCard: {
    padding: '0px 20px',
    textAlign: 'center',
  },
  accordionSection: {
    padding: '20px 0px',
  },
  contentText: {
    textAlign: 'start',
  },
  '@media screen and (max-width: 780px)': {
    root: {
      width: '90%',
    },
  },
}

export default styles
