const styles = {
  cardRoot: {
    width: '80%',
    margin: 'auto',
    padding: '20px 0px 40px 0px',
    '& h4': {
      fontSize: '32px',
      color: '#000d51',
      margin: '35px 0px 25px 0px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  gridCard: {
    padding: '0px 20px',
    textAlign: 'center',
    margin: ' auto',
  },
  contentArticle: {
    textAlign: 'start',
    cursor: 'pointer',
    '& span': {
      color: '#fff',
    },
  },
  mainContent: {
    justifyContent: 'center',
  },
  contentSection: {
    maxWidth: '180px',
    margin: '30px',
  },
  sectionReading: {
    border: '12px solid #DD0530',
    borderRadius: '15px',
    borderStyle: 'inset',
    background: '#faf7db',
    height: '180px',
    width: '180px',
    color: '#DD0530',
    cursor: 'pointer',
    margin: '0px',
    '& span': {
      fontSize: '120px',
    },
  },
  contentTitle: {
    width: '50%',
    margin: '0px auto 40px auto',
    color: '#f60',
    border: '3px solid #f60',
    '& h2': {
      margin: '5px 0px 0px 0px',
    },
  },
  videoContent: {
    maxWidth: '100%',
    height: '100%',
  },
}

export default styles
